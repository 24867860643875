<template>
  <div class="mainPage">
    <p id="headPage">Mutasi APD</p>
    <div class="cardRow"> 
       
      <div class="inRow">
        <div class="card" id="startBalance">
          <p id="titleCard">Saldo Awal</p>
          <p id="amountCard">{{formatUang(totalBalance.startBalance)}}</p>
        </div>
        <div class="card" id="inCard">
          <p id="titleCard">APD Masuk</p>
          <p id="amountCard">{{formatUang(totalBalance.addDebt)}}</p>
        </div>
        <div class="card" id="outCard">
          <p id="titleCard">APD Keluar</p>
          <p id="amountCard">{{formatUang(totalBalance.payDebt)}}</p>
        </div>
        <div class="card" id="balanceCard">
          <p id="titleCard">Saldo Akhir</p>
          <p id="amountCard">{{formatUang(totalBalance.endBalance)}}</p>
        </div>
      </div>
    </div>
    <div id="titleRow">
      <b-button id="addModal" @click.prevent="showAdd()">Tambah Data</b-button>
      <!-- <b-button id="addModal" @click.prevent="fixBug()" >Fix</b-button> -->
      <button @click.prevent="printCsv()" id="printer">
          <img src="../assets/printer.png" id="printer" alt="">
      </button>
    </div>
    <div class="filterRow">
       
      <div class="inRow">
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Cabang</p>
          <b-select v-model="BranchId" @change="this.getDataTable">
            <option value="0" >Semua Cabang </option>
            <option v-for="e in this.branches" :key="e.id" :value="e.id"> {{e.branchName}} </option>
          </b-select>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle" >Tanggal Mulai</p>
          <b-input type="date"  v-model="startDate"  @change="this.getDataTable"></b-input>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Tanggal Selesai</p>
          <b-input type="date" v-model="endDate"  @change="this.getDataTable"></b-input>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Kata Kunci</p>
          <b-input v-model="keyword" @change="this.getDataTable"></b-input>
        </div>

      </div>
      <div class="inRow">


      </div>
    </div>
    <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p> 
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>Tanggal</th>
            <th>Nomor Ref.</th>
            <!-- <th>Cabang</th> -->
            <th>Keterangan</th>
            <th>Jenis Nota</th>
            <th>Total Harga</th>
            <!-- <th colspan="2">Aksi</th> -->
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in this.dataTable" :key="e.id">
            <!-- <td>{{ e }}</td> -->
            <td>{{ formatDate(e.date) }}</td>
            <td> {{ e.refNo }}</td>
            <td> {{e.notes}} </td>
            <td v-if="e.type == 1"> Masuk </td>
            <td v-if="e.type == 2"> Keluar </td>
            <td v-if="e.type == 3"> Pemakaian Langsung </td>
            <td class="text-right"> {{ formatUang(totalAmount(e.ApdSubBills)) }} </td>
            <!-- <td>{{ e.Produck.produckName }}</td> -->
            <td id="action" class="text-center" >
              <button @click.prevent="setPrintData(e)" title="Print" >
                  <img src="../assets/print2.png" alt="">
              </button>
              <button @click.prevent="confirmDelete(e.id)" title="Hapus">
                  <lord-icon
                      src="https://cdn.lordicon.com/gsqxdxog.json"
                      trigger="hover">
                  </lord-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    </div>

    <!-- modal -->
  <div class="modal" id="mainModal" tabindex="-1">
  <!-- <div class="modal-dialog"> -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Mutasi APD</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" >&times; </span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="isEdit ? editData() : addData()">

        <div class="inRow">
          <div class="inCol">
            <p id="titleModal">Jenis Mutasi</p>
            <b-select v-model="add.type" required>
              <option :value="1"> Masuk </option>
              <option :value="2"> Keluar </option>
              <option :value="3"> Pemakaian Langsung </option>
            </b-select>
          </div>
          <div class="inCol">
            <p id="titleModal">Tanggal</p>
            <b-input type="date" v-model="add.date" required></b-input>
          </div>

          <div class="inCol">
            <p id="titleModal">Keterangan</p>
            <b-input type="text"  v-model="add.notes" placeholder="Masukkan Catatan" required ></b-input>
          </div>
        </div>
        <div class="inRow" style="justify-content: flex-start;">
          <b-button class="mt-3 " id="addModal" @click.prevent="addRow()">Tambah Baris</b-button>
        </div>

        <div class="inRow"  style="justify-content: space-around;" >
          <p>Jenis Sparepart</p>
          <p>Volume</p>
          <p v-if="this.add.type == 1 ||this.add.type == 3 ">Total Harga</p>
        </div>
        <div class="inRow" v-for="e in add.subBill" :key="e.id" >
          <b-select v-model="e.ApdId">
            <option v-for="e in sparepart" :key="e.id" :value="e.id">{{ e.name}} -  {{ e.type }} - {{ e.size }} - {{ e.color }}</option>
          </b-select>
          <b-input class="text-right" v-model="e.volume" min="1"></b-input>
          <b-input class="text-right" @input="formatAmount(e)" v-model.lazy="e.amount" min="100" v-if=" add.type == 1 ||add.type == 3"></b-input>
          <button @click.prevent="deleteRow(e.id)">
            <img src="../assets/trash.png" alt="">
          </button>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary"> {{ isEdit ? 'Edit' : 'Tambah' }} </button>
        </div>
      </form>
      </div>
    </div>
  <!-- </div> -->
</div>
  <!-- Print -->
  <div id="printArea">

<div class="headPrint">
  <p id="titlePrint">{{ print.type }}</p>
  <p id="brancName"> KURNIA JAYA KARYA</p>
  <p id="datePrint"> {{ print.date }} </p>
</div>
<hr>
<div class="mainPrint">
  <table class="table table-borderless" id="printTable">
      <tr>
        <td id="keyTable">Nomor</td>
        <td id="titikDua">:</td>
        <td>{{ print.refNo }}</td>
      </tr>
      <tr>
        <td id="keyTable">Total</td>
        <td id="titikDua">:</td>
        <td>Rp.{{ formatUang(print.amount) }}</td>
      </tr>

      <tr >
        <td id="keyTable" >Keterangan</td>
        <td id="titikDua">:</td>
        <td>{{ print.notes }}</td>
      </tr>
  </table>
  <hr>
</div>
<div class="mainPrint">
  <table class="table table-bordered" id="printTable">
    <thead>
      <tr>
        <th>Item</th>
        <th>Volume</th>
        <th>Harga</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="e in print.subBill" :key="e.id">
        <td>{{ e.Apd.name }} - {{ e.Apd.type }} - {{ e.Apd.size }}</td>
        <td class="text-right">{{ formatUang(e.volume) }}</td>
        <td class="text-right">{{ formatUang(e.amount) }}</td>
      </tr>
    </tbody>
  </table>
  <hr>
</div>
<div class="signArea">
  <div class="inRow">
    <div class="inCol">
      <p id="name">Staff Gudang</p>
      <p>({{ name }})</p>
    </div>
    <div class="inCol" >
      <p id="name" >Pemberi / Penerima Barang</p>
      <p>(............................)</p>
    </div>
    <div class="inCol">
      <p id="name">Akuntansi</p>
      <p>(............................)</p>
    </div>
    <div class="inCol">
      <p id="name">Direktur</p>
      <p>(............................)</p>
    </div>
  </div>
</div>
<hr>
  </div>
</div>
</template>

<script>
import Vue from 'vue';
import axios  from '../API/axios'
import Swal from "sweetalert2";
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment'
import html2pdf from "html2pdf.js";
import csvDownload from 'json-to-csv-export'
Vue.filter('currency', function (value) {
  if (!value) return ''
  let amount = value.toString().replace(/\D/g, '')
  return Number(amount).toLocaleString('id-ID')
});

export default {
  name: "Voucer",
  data() {
    return  {
      BranchId: 0,
      ProduckId: 0,
      dataTable: [],
      id: 0,
      currentPage: 1,
      perPage: 20,
			totalPage : 1,
      isLoading: false,
      isEdit: false,
      startDate: '',
      endDate: '',
      type: 1,
      keyword :'',
      SparepartId: 0,
      totalBalance: {},
      // fill modal
      sparepart: [],
      projects: [],
      transport: [],
      // filter
      subBill: [],
      add: {
        BranchId: 0,
        date: '',
        type: 1,
        notes: '',
        subBill: []
      },
      print: {
        project: '',
        date: '',
        amount: '',
        subBill: [],
        refNo: '',
        type: '',
      }
    }
  },
  created() {
    this.getDataTable()
    this.getSparepart()
    this.$store.dispatch("getBranch")
  },
  computed: {
    branches() {
      return this.$store.state.branches
    },
    name() {
      return localStorage.getItem('username')
    },
    role() {
      return localStorage.getItem("role");
    },
  },  
  methods: {

    showAdd() {
      this.isEdit = false
      this.add.ProjectId = 0
      this.add.BranchId = 0
      this.add.type = 0
      this.add.notes = ''
      if(this.add.subBill.length < 1) {
        this.addRow()
      }
      this.type = ""
      $('#mainModal').modal('show')
    },

    showEdit(e) {
      console.log(e);
      this.produckName = e.produckName
      this.type = e.type
      
      this.subItems = e.StandartProducks
      this.id = e.id
      this.isEdit = true
      $('#mainModal').modal('show')
    },

    addRow() {
      // alert("yeess")
      this.add.subBill.push({
        id: uuidv4(),
          ApdId: 0,
          volume: 0,
          amount: 0
      })
    },

    async getDataTable() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/apd/mutation",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            BranchId: this.BranchId,
            keyword: this.keyword,
            startDate: this.startDate,
            endDate: this.endDate,
            page: this.currentPage,
            limit: this.perPage,
          }
        })
        this.dataTable = data.data2.result
        let totalPage = Math.ceil(data.data2.dataLength / this.perPage)
				this.totalPage = totalPage
        this.totalBalance = data.totalBalance
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
    formatAmount(data) {
      data.amount = data.amount.toString().replace(/\D/g, '')
      data.amount = Number(data.amount).toLocaleString('id-ID')
    },
    formatUang(data) {
      // console.log(data);
			let uang = 0;
      if(data) {
        uang = ""
        data = data.toString();
        for (let i = 0; i < data.length; i++) {
          if ((data.length - i) % 3 == 0 && i !== 0) {
          uang += `.${data[i]}`;
          } else {
          uang += data[i];
          }
        }
      }
      return uang
    },
    async getProject() {

      try {
        let {data} = await axios({
          url: "/user/project",
          params: {
           BranchId:  this.add.BranchId
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.projects = data
      } catch(err) {
        console.log(err);
      }
    },

    async getSparepart() {
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/apd",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        console.log(data);
        this.sparepart = data

      } catch(err) {

        console.log(err);
      }
    },


    async addData() {

      let bills = this.add.subBill
      if(this.add.type == 1 ||  this.add.type == 3) {
        bills = bills.map(e => {
          return {
            ...e,
            amount: parseInt(e.amount.replace(/\./g, ""))
          }
        })
      }
      console.log(bills," ===== bilss");
      let payload = {
        BranchId: this.add.BranchId,
        date: this.add.date,
        type: this.add.type,
        notes: this.add.notes,
        subBill: bills
      }
      console.log(payload.bills);

      try {

        let proses = await axios({
          method: "POST",
          url: "/apd/mutation",
          data: payload,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.getDataTable()
        $('#mainModal').modal('hide')
        Swal.fire('Berhasil', 'Telah diproses', 'success');
      } catch(err) {
        console.log(err.response.data);
        this.isLoading = false
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    },
    async editData(e) {

      try {

        if(this.subItems.length < 1) {
          throw {
            status: 500,
            msg: 'No'
          }
        }

        this.subItems.map(e => {
          if(e.MaterialId == 0 || e.volume == 0) {
            throw {
            status: 500,
            msg: 'Data is not complete'
          }
          }
        })

        let payload = {
            items: this.subItems,
            ProduckId: this.id,
            produckName : this.produckName,
            type : this.type,
        }
        let data3 = await axios({
          method: "post",
          url: `/apd/standar`,
          data: payload,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        let data2 = await axios({
          method: "put",
          url: `/apd/${this.id}`,
          data: payload,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })



        $('#mainModal').modal('hide')
        this.produckName=  ""
        this.type=  ""
        this.id=  0
        this.isEdit = false
        this.getDataTable()
        this.$store.dispatch("getBranch")

        Swal.fire('Berhasil', 'Data Berhasil Diubah', 'success');
        
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    }, 

    async deleteData(id) {
      
      try {
        
        let data = await axios.delete(`/apd/mutation/${id}`, {
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Berhasil', 'Data Berhasil Dihapuskan', 'success');
        this.$store.dispatch("getBranch")
        this.getDataTable()
      } catch(err) {
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');

      } 
    },
    async confirmDelete(id) {
      
      try {
        let data = await Swal.fire({
            title: 'Yakin?',
            text: 'Apakah Yakin ',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Hapus!!',
        })
        if (data.isConfirmed) { 
            await this.deleteData(id);
            this.$store.dispatch("getBranch")
            this.getDataTable()
        } else {
                Swal.fire('Ok', 'Proses anda berhasil dibatalkan', 'error');
              }
              
              
            } catch(err) {
              
              Swal.fire('Maaf', 'Proses anda berhasil dibatalkan', 'error');
      }
    },
    getPlatNumber(e) {

      let result = '-'
      if(e.Transport) {
        result = `${e.Transport.type} -  ${e.Transport.platNumber}`
      }

      return result
    },
    totalAmount(data) {

      let total = 0
      data.map(e => {
        total += e.amount
      })

      return total
    },

    formatDate(date) {
      return moment(date).format("DD-MM-YYYY")
    },
    deleteRow(id) {
      this.add.subBill = this.add.subBill.filter(e => e.id !== id)
    },  
    setPrintData(e) {
      console.log(e);
      let total = 0
      e.ApdSubBills?.map(e => {
        total += e.amount
      })
      this.print.amount = total
      this.print.date = this.formatDate(e.date)
      // this.print.branchName = e.Branch.branchName
      this.print.type = e.type == 1 ? 'Sparepart Masuk' : 'Sparepart Keluar'
      this.print.refNo = e.refNo
      this.print.notes = e.notes
      this.print.subBill = e.ApdSubBills
      this.exportToPDF()
      
    },
    async printCsv() {
      // alert("no")

      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/apd/print/all",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            // startDate: this.startDate,
            // keyword: this.keyword,
            // endDate: this.endDate,
            // page: this.currentPage,
            // limit: this.perPage,
            // BranchId: this.findBranchId,
            // type: this.findType, 
            // status: this.findStatus, 
          }
        })
        console.log(data,"-------------");
        const dataToConvert = {
          data: data,
          filename: 'Balance By Balance',
          delimiter: ',',
        }
        csvDownload(dataToConvert)
        this.isLoading= false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }
    },
    exportToPDF() {
      this.isLoading = true
      document.getElementById('printArea').style.display='block'
      html2pdf(document.getElementById('printArea'),  {
        margin: 1,
        filename: `Apd-${this.print.refNo}.pdf`,
      }).then(e => {
        document.getElementById('printArea').style.display='none'
        this.isLoading = false
      })
    },
    addPage() {
      this.currentPage = this.currentPage + 1
          this.getDataTable()
    },
    minPage() {
      this.currentPage = this.currentPage - 1
          this.getDataTable()
    },
  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }



 




</style>