<template>
  <div class="snowfall-container">
    <div class="music-player">
    <audio
      ref="audio"
      src="https://www.youtube.com/watch?v=sQk9W5Earrc"
      preload
      loop
      autoplay
      id="audio"
    ></audio>
  </div>
  </div>
</template>
  
<script>
import axios from '../API/axios';
import Swal from "sweetalert2";
import moment from 'moment';

export default {
  name: "LoginPage",
  data() {
    return {
      musicSourch:"../assets/haiDunia.mp3",
    };
  },
};
</script>