<template>
  <div class="mainPage">
    <p id="headPage">Hutang</p>
    <!-- <div class="cardRow">
       
      <div class="inRow">
        <div class="card" id="startBalance">
          <p id="titleCard">Saldo Awal</p>
          <p id="amountCard">Rp. 1.000.0000</p>
        </div>
        <div class="card" id="inCard">
          <p id="titleCard">Kas Masuk</p>
          <p id="amountCard">Rp. 100.000.0000.000</p>
        </div>
        <div class="card" id="outCard">
          <p id="titleCard">Kas Keluar</p>
          <p id="amountCard">Rp. 1.000.0000</p>
        </div>
        <div class="card" id="balanceCard">
          <p id="titleCard">Saldo Akhir</p>
          <p id="amountCard">Rp. 1.000.0000</p>
        </div>
      </div>
    </div> -->
    <div class="filterRow">

      <div class="inRow">
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Cabang</p>
          <b-select v-model="findBranchId" @change="this.getFindVendor">
            <option value="0" disabled>Pilih Cabang </option>
            <option v-for="e in this.branches" :key="e.id" :value="e.id"> {{e.branchName}} </option>
          </b-select>
        </div>
        <div class="inCol"  id="cardFilter">
          <p id="inputTitle">Vendor</p>
          <b-select v-model="findHutangVendorId"  @change="this.getDataTable">
            <option v-for="e in this.findVendors" :key="e.id" :value="e.id"> {{e.vendorName}} </option>
          </b-select>
        </div>
        <div class="inCol" id="cardFilter">
          
          <p id="inputTitle" >Tanggal Mulai</p>
          <b-input type="date"  v-model="startDate"  @change="this.getDataTable"></b-input>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Tanggal Selesai</p>
          <b-input type="date" v-model="endDate"  @change="this.getDataTable"></b-input>
        </div>
      </div>
    </div>
    <div id="titleRow">
      <b-button id="addModal" @click.prevent="showAdd()" v-if="role == 'hutang'|| role == 'adminTeknik' " >Tambah</b-button>
      <b-button id="resetFilter" @click.prevent="resetFilter()">Reset Filter</b-button>
      <button @click.prevent="printCsv()" id="printer">
          <img src="../assets/printer.png" id="printer" alt="">
      </button>
    </div>
    <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p> 
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>Tanggal</th>
            <th>No.Reff</th>
            <th>Cabang</th>
            <th>Vendor</th>
            <th>Keterangan</th>
            <th>Jenis Transaksi</th>
            <th>Jumlah</th>
            <th>Kelengkapan</th>
            <th>Telah Bayar</th>
            <!-- <th colspan="2">Aksi</th> -->
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in this.dataTable" :key="e.id">
            <td>{{formatDate(e.date)}} </td>
            <td> {{e.refNo}} </td>
            <td> {{e.Branch?.branchName || " "}} </td>
            <td>{{ e.HutangVendor?.vendorName ? e.HutangVendor.vendorName : e.HutangVendorId }}</td>
            <!-- <td> {{e.HutangVendor.vendorName || e.HutangVendorId}} </td> -->
            <td> {{e.notes}} </td>
            <td> {{ e.transactionType == 1 ? "Penambahan" : "Pengurangan"  }} </td>
            <td class="uang"> {{ formatUang(e.amount)}} </td>
            <td class="text-center" >
                <img src="../assets/check.png" v-if="e.isComplete">
                <img src="../assets/wrong.png" v-if="!e.isComplete"> 
            </td>   
            <td class="text-center" >
                <img src="../assets/check.png" v-if="e.isStatus">
                <img src="../assets/wrong.png" v-if="!e.isStatus">
            </td>   
            <td id="action" class="text-center" >
              <button @click.prevent="setPrintData(e)" title="Print" >
                  <img src="../assets/print2.png" alt="">
                </button>
                <button @click.prevent="showEdit(e)" title="Edit" v-if="role == 'hutang' || role == 'adminTeknik'">
                  <lord-icon
                    src="https://cdn.lordicon.com/nxaaasqe.json"
                    trigger="hover">
                  </lord-icon>
                </button>
                <button @click.prevent="confirmDelete(e.id)" v-if="role == 'hutang' || role == 'adminTeknik'" title="Hapus">
                    <lord-icon
                        src="https://cdn.lordicon.com/gsqxdxog.json"
                        trigger="hover">
                    </lord-icon>
                </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    </div>

    <!-- modal -->
  <div class="modal" id="mainModal" tabindex="-1">
  <!-- <div class="modal-dialog"> -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Hutang</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" >&times; </span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="isEdit ? editData() : addData()">

          <div class="inRow">
            <div class="inCol">
              <p id="titleModal">Cabang</p>
              <b-select v-model="BranchId" required @change="this.getProject">
                <option value="0" disabled>Pilih Cabang </option>
                <option v-for="e in this.branches" :key="e.id" :value="e.id"> {{e.branchName}} </option>
              </b-select>
            </div>
            <div class="inCol">
              <p id="titleModal">Vendor</p>
              <b-select v-model="HutangVendorId" required>
                <option value="0" disabled>Pilih Vendor </option>
                <option v-for="e in this.vendors" :key="e.id" :value="e.id"> {{e.vendorName}} </option>
              </b-select>
            </div>
        
        </div>
        <div class="inRow">
          <div class="inCol">
            <p id="inputTitle">Jenis</p>
            <b-select v-model="transactionType" required>
              <option :value=1> Penambahan </option>
              <option :value=2> Pengurangan </option>
            </b-select>
          </div>
          <div class="inCol">
            <p id="inputTitle">Tanggal</p>
            <b-input v-model="date" type="date" required></b-input>
          </div>
        </div>
        <div class="inRow">
          <div class="inCol">
            <p id="inputTitle">Jumlah</p>
            <b-input type="text" class="text-right" v-model="amount"  @input="currencyNumber($event)"></b-input>
          </div>
          <div class="inCol">
            <p id="inputTitle">Keterangan</p>
            <b-input type="text" v-model="notes" name="detail" required></b-input>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary">Proses</button>
        </div>
      </form>
      </div>
    </div>
  <!-- </div> -->
</div>
<!-- print -->
<div id="printArea">

<div class="headPrint">
  <p id="titlePrint" v-if="this.print.transactionType == 'Penambahan'">Penambahan Hutang</p>
  <p id="titlePrint" v-if="this.print.transactionType == 'Pengurangan' ">Pengurangan Hutang</p>
  <p id="brancName"> {{ print.Branch }} </p>
  <p id="datePrint"> {{ print.date }} </p>
</div>
<hr>
<div class="mainPrint">
  <table class="table table-borderless" id="printTable">
      <tr>
        <td id="keyTable">Nomor</td>
        <td id="titikDua">:</td>
        <td>{{ print.refNo }}</td>
      </tr>
      <tr>
        <td id="keyTable">Jumlah</td>
        <td id="titikDua">:</td>
        <td>Rp.{{ print.amount }}</td>
      </tr>
      <tr>
        <td id="keyTable">Keterangan</td>
        <td id="titikDua">:</td>
        <td>{{ print.notes }}</td>
      </tr>
  </table>
  <hr>
</div>
<div class="signArea">
  <div class="inRow">
    <div class="inCol">
      <p id="name">Kasir</p>
      <p>(............................)</p>
    </div>
    <div class="inCol">
      <p id="name">Vendor</p>
      <p>({{ print.vendor }})</p>
    </div>
    <div class="inCol">
      <p id="name">Akuntansi</p>
      <p>(............................)</p>
    </div>
    <div class="inCol">
      <p id="name">Direktur</p>
      <p>(............................)</p>
    </div>
  </div>
</div>
<hr>
</div>
  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import moment from 'moment'
import csvDownload from 'json-to-csv-export'
import html2pdf from "html2pdf.js";
export default {
  name: "Voucer",
  data() {
    return  {
      id: 0,
      projects: [],
      vendors: [],
      dataTable: [],
      types: [],
      currentPage: 1,
      perPage: 20,
			totalPage : 2,
      isLoading: false,
      isEdit: false,
      
      // filter
      findBranchId: 0,
      findProjectId: 0,
      findHutangVendorId: 0,
      findHutangTypeId: 0,
      endDate: "",
      startDate: "",
      keyword: "",

      findVendors: [],
      findProcejts: [],
    
      // modal
      BranchId: "",
      ProjectId: 0,
      HutangVendorId: 0,
      transactionType: "",
      date:"",
      HutangTypeId: 1,
      notes: "",
      amount: 0,

      // print 
      print: {
        date: '',
        refNo: '',
        Branch: '',
        vendor: '',
        transactionType: '',
        notes: '',
        type: '',
        amount: ''
      }
    }
  },
  created() {
    this.$store.dispatch("getBranch")
    this.getDataTable()
    this.getType()
  },
  computed: {
    branches() {
      return this.$store.state.branches
    },
    role() {
      return localStorage.getItem("role");
    },
  },  
  methods: {

    showAdd() {
      this.isEdit = false
      $('#mainModal').modal('show')
    },
    showEdit(e) {
      this.getProject()
      this.id = e.id
      this.isEdit = true
      $('#mainModal').modal('show')
     this.BranchId=  e.BranchId
     this.date=  e.date
     this.notes=  e.notes
     this.amount=  e.amount
     this.ProjectId= e.ProjectId
     this.HutangVendorId= e.HutangVendorId
     this.transactionType= e.transactionType
     this.HutangTypeId= e.HutangTypeId
    },
    resetFilter() {
      this.findBranchId= 0
      this.findProjectId= 0
      this.findHutangVendorId= 0
      this.findHutangTypeId= 0
      this.endDate= ""
      this.startDate= ""
      this.keyword= ""
      this.getDataTable()
    },
    async changeStatus(id) {
      
      try {
        
        let data2 = await axios({
          method: "patch",
          url: `/voucer/isopen/${id}`,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Berhasil', 'Data berhasil diproses', 'success');
        this.getDataTable()
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    },
    async getProject() {
      try {
        let project = await axios({
          url: "/user/project",
          params: {
           BranchId:  this.BranchId
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.projects = project.data
        this.getVendor()
      } catch(err) {
        console.log(err);
      }
    },  

    async getVendor() {
      try {
        let vendor = await axios({
          url: "/hutang/vendor",
          params: {
           BranchId:  this.BranchId
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.vendors = vendor.data
      } catch(err) {
        console.log(err);
      }
    },  
    async getFindVendor() {
      try {
        this.getFIndProject()
        this.getDataTable()
        let vendor = await axios({
          url: "/hutang/vendor",
          params: {
           BranchId:  this.findBranchId
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.findVendors = vendor.data
      } catch(err) {
        console.log(err);
      }
    },  
    async getFIndProject() {
      try {
        let project = await axios({
          url: "/user/project",
          params: {
           BranchId:  this.findBranchId
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.findProcejts = project.data
      } catch(err) {
        console.log(err);
      }
    },  

    async getType() {
      try {
        let type = await axios({
          url: "/hutang/type",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.types = type.data
      } catch(err) {
        console.log(err);
      }
    },
    async printCsv() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/hutang/print",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            startDate: this.startDate,
            endDate: this.endDate,
            BranchId: this.findBranchId,
            HutangVendorId: this.findHutangVendorId, 
            HutangTypeId: this.findHutangTypeId, 
            ProjectId: this.findProjectId, 
          }
        })
        const dataToConvert = {
          data: data,
          filename: 'Hutang',
          delimiter: ',',
        }
        csvDownload(dataToConvert)
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY")
    },
    cekStatus(data) {
      let result = ""
      if(data == 2) {
        result = 'By Planing'
      } else if(data == 1) {
        result= 'Urgent'
      }
      return result
    },
    formatUang(data) {
      // console.log(data);
			let uang = "";
      if(data) {
        data = data.toString();
        for (let i = 0; i < data.length; i++) {
          if ((data.length - i) % 3 == 0 && i !== 0) {
          uang += `.${data[i]}`;
          } else {
          uang += data[i];
          }
        }
      }
      return uang
    },
    currencyNumber(event, target) {
        console.log(event, "===================");
        let currentValue = event.trim().split('.').filter(e => e != '.' ).join('')

        console.log(currentValue, typeof currentValue," -----------");
        if( Number.isNaN(Number(currentValue))) {
          // this.amount = 10
          this.amount = this.formatUang(currentValue.split('').filter(e => !Number.isNaN( Number(e))).join(''))
          return
        }
        // currentValue = this.amount
        this.amount = this.formatUang(currentValue)
        // currentValue = this.amount
        // this.amount = this.amount.toLocaleString('id-ID')
        console.log(this.amount, "++++++++++++++");
    },

    async getDataTable() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/hutang",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            startDate: this.startDate,
            endDate: this.endDate,
            page: this.currentPage,
            limit: this.perPage,
            BranchId: this.findBranchId,
            HutangVendorId: this.findHutangVendorId, 
            HutangTypeId: this.findHutangTypeId, 
            ProjectId: this.findProjectId, 
          }
        })
        this.dataTable = data.data2.result
        let totalPage = Math.ceil(data.data2.dataLength / this.perPage)
				this.totalPage = totalPage
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },

    async addData() {

      try {


        let data =  {
          BranchId:  this.BranchId,
          date:  this.date,
          notes:  this.notes,
          amount:  this.amount.trim().split('.').filter(e => e != '.' ).join(''),
          ProjectId: this.ProjectId,
          HutangVendorId: this.HutangVendorId,
          transactionType: this.transactionType,
          HutangTypeId: this.HutangTypeId,
        }

        let data2 = await axios({
          method: "POST",
          url: "/hutang",
          data: data,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        $('#mainModal').modal('hide')
        this.BranchId= 0
        this.ProjectId= 0
        this.HutangVendorId= 0
        this.transactionType= ""
        this.date=""
        this.HutangTypeId= ""
        this.notes= ""
        this.amount= 0
        Swal.fire('Berhasil', 'Data Berhasil Ditambahkan', 'success');
        this.getDataTable()
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    },

    async editData(e) {

      try {
        let payload =  {
          BranchId:  this.BranchId,
          date:  this.date,
          notes:  this.notes,
          amount:  this.amount,
          ProjectId: this.ProjectId,
          HutangVendorId: this.HutangVendorId,
          transactionType: this.transactionType,
          HutangTypeId: this.HutangTypeId,
        }

        let data2 = await axios({
          method: "put",
          url: `/hutang/${this.id}`,
          data: payload,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        $('#mainModal').modal('hide')
        this.getDataTable()
        this.BranchId=  0
        this.date=  ""
        this.ProjectId=  ""
        this.HutangVendorId=  ""
        this.notes=  ""
        this.transactionType=  ""
        this.HutangTypeId=  ""
        this.amount=  0
        this.id=  0
        Swal.fire('Berhasil', 'Data Berhasil Ditambahkan', 'success');
        
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    }, 

    async deleteData(id) {
      
      try {
        
        let data = await axios.delete(`/hutang/${id}`, {
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Berhasil', 'Data Berhasil Dihapuskan', 'success');

      } catch(err) {
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');

      } 
    },
    async confirmDelete(id) {
      
      try {
        let data = await Swal.fire({
            title: 'Yakin?',
            text: 'Apakah Yakin ',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Hapus!!',
        })
        if (data.isConfirmed) {
            this.deleteData(id);
            this.getDataTable()
        } else {
                Swal.fire('Ok', 'Proses anda berhasil dibatalkan', 'error');
              }
              
              
            } catch(err) {
              
              Swal.fire('Maaf', 'Proses anda berhasil dibatalkan', 'error');
      }
    },

    setPrintData(e) {
      this.print.Branch = e.Branch.branchName
      this.print.date = this.formatDate(e.date)
      this.print.vendor = e.HutangVendor.vendorName
      this.print.refNo = e.refNo
      this.print.notes = e.notes
      this.print.amount = this.formatUang(e.amount)
      this.print.transactionType = e.transactionType == 1 ? 'Penambahan' :  "Pengurangan" 
      this.exportToPDF()
    },
    exportToPDF() {
      this.isLoading = true
      document.getElementById('printArea').style.display='block'
      html2pdf(document.getElementById('printArea'),  {
        margin: 1,
        filename: "Hutang.pdf",
      }).then(e => {
        document.getElementById('printArea').style.display='none'
        this.isLoading = false
      })
    },

    addPage() {
      this.currentPage = this.currentPage + 1
      this.getDataTable()
    },
    minPage() {
      this.currentPage = this.currentPage - 1
      this.getDataTable()
    },
  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }




</style>