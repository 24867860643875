<template>
  <div class="mainPage">
    <p id="headPage">Kendaraan</p>
    <div class="filterRow">
       
      <div class="inRow">
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Merek</p>
          <b-select v-model="find.merk" @change="this.getDataTable">
            <option value="0" disabled>Pilih Type Kendaraan </option>
            <option v-for="(e,index) in this.merk" :key="index" :value="e"> {{e}} </option>
          </b-select>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Type</p>
          <b-select v-model="find.type" @change="this.getDataTable">
            <option v-for="(e,index) in this.types" :key="index" :value="e"> {{e}} </option>
          </b-select>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Status</p>
          <b-select v-model="find.status" @change="this.getDataTable">
            <option value="0"> Seluruh Kendaraan</option>
            <option value="1"> Baik </option>
            <option value="2"> Rusak </option>
          </b-select>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Keyword</p>
          <b-input type="text" class="text-right" v-model="find.keyword" @change="this.getDataTable"></b-input>
        </div>

      </div>
    </div>
    <div id="titleRow">
      <b-button id="addModal" @click.prevent="showAdd()">Tambah</b-button>
      <b-button id="resetFilter" @click.prevent="resetFilter()">Reset Filter</b-button>
      <button @click.prevent="printCsv()" id="printer">
          <img src="../assets/printer.png" id="printer" alt="">
      </button>
    </div>
    <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p> 
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>Plat Nomor</th>
            <th>Merek</th>
            <th>Type</th>
            <th>Tahun</th>
            <th>Nilai Pasar</th>
            <th>Status</th>
            <th>Catatan</th>
            <th>Harga Pasar</th>
            <th v-if="role == 'owner'">Total Sparepart</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in this.dataTable" :key="e.id">
            <td class="text-left">  {{e.platNumber}}</td>
            <td>{{e.merk}} </td>
            <td>{{e.type}} </td>
            <td class="text-right">{{e.year}} </td>
            <td class="text-right">{{ formatUang(e.amount)}} </td>
            <td>{{e.status?"Baik" : 'Rusak'}} </td>
            <td>{{e.notes}} </td>
            <td class="text-right">{{ formatUang(e.amount)}} </td>
            <td class="text-right" v-if="role == 'owner'">{{ formatUang(e.totalAmount)}} </td>
            <td id="action" class="text-center" >
              <button @click.prevent="showEdit(e)" title="Edit" >
                <img src="../assets/edit.png" alt="">
              </button>
              <button @click.prevent="confirmDelete(e.id)" title="Hapus">
                <img src="../assets/trash.png" alt="">
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    </div>

    <!-- modal -->
    <div class="modal" id="mainModal" tabindex="-1">
  <!-- <div class="modal-dialog"> -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Piutang</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" >&times; </span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="isEdit ? editData() : addData()">

          <div class="inRow">
            <div class="inCol" v-if="role == 'owner'">
              <p id="titleModal">Nama Cabang</p>
              <b-select v-model="add.BranchId">
                <option :value="e.id" v-for="e in this.branches" :key="e.id">{{ e.branchName }}</option>
              </b-select>
            </div>
            <div class="inCol">
              <p id="titleModal">Vendor</p>
              <b-input v-model="vendorName" type="text" required> </b-input>
            </div>
        
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary">Tambah</button>
        </div>
      </form>
      </div>
    </div>
  <!-- </div> -->
</div>

<!-- Truck -->
    <div class="modal" id="truckModal" tabindex="-1">
      <!-- <div class="modal-dialog"> -->
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Kendaraan</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" >&times; </span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="isEdit ? editCar() : addCar() ">
              <div class="inRow">
                <div class="inCol" v-if="role == 'owner'">
                  <p id="titleModal">Nama Cabang</p>
                  <b-select v-model="add.BranchId">
                    <option :value="e.id" v-for="e in this.branches" :key="e.id">{{ e.branchName }}</option>
                  </b-select>
                </div>
                <div class="inCol">
                  <p id="titleModal">Plat Nomor</p>
                  <b-input v-model="add.platNumber" type="text" name="platNumber" required></b-input>
                </div>
                <div class="inCol" >
                  <p id="titleModal">Merk</p>
                  <b-select v-model="add.merk" required>
                    <option v-for="(e,index) in this.merk" :key="index" :value="e"> {{e}} </option>
                  </b-select>
                </div>
                <div class="inCol" >
                  <p id="titleModal">Type Kendaraan</p>
                  <b-select v-model="add.type" required>
                    <option v-for="(e,index) in this.types" :key="index" :value="e"> {{e}} </option>
                  </b-select>
                </div>

                <div class="inCol">
                  <p id="titleModal">Tahun</p>
                  <b-input v-model="add.year" type="number" min="1970" max="2024" required></b-input>
                </div>
                <div class="inCol">
                  <p id="titleModal">Harga Pasar</p>
                  <b-input class="text-right" v-model="add.amount" type="text" @input="currencyNumber($event)" required></b-input>
                </div>
                <div class="inCol">
                  <p id="titleModal">Catatan</p>
                  <b-input v-model="add.notes" type="text" required></b-input>
                </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="submit" class="btn btn-primary">Tambah</button>
            </div>
          </form>
          </div>
        </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import moment from 'moment'
import csvDownload from 'json-to-csv-export'

export default {
  name: "Voucer",
  data() {
    return  {
      id: 0,
      isEdit: false,
      dataTable: [],
      currentPage: 1,
      perPage: 20,
			totalPage : 1,
      isLoading: false,
      totalBalance: "",
      
      // filter

      find: {
        type: "",
        merk: "",
        status: '',
        keyword: '',
        BranchId: ''
      },
      add: {
        BranchId: '',
        platNumber: '',
        type: '',
        merk: '',
        amount: '',
        year: '',
        notes: '',
      },

      // add
      BranchId: 0,
      vendorName : ""
    
    }
  },
  created() {
    this.$store.dispatch("getBranch")
    this.getDataTable()
  },
  computed: {
    branches() {
      return this.$store.state.branches
    },
    role() {
      return localStorage.getItem("role");
    },
    merk() {
      return this.$store.state.merk
    },
    types() {
      return this.$store.state.types
    },
    
  },  
  methods: {
    resetFilter() {
      this.find = {
        type: "",
        size: "",
        status : '',
        keyword: ''
      }
      this.getDataTable()
    },
    showAdd() {
      this.isEdit = false
      $('#truckModal').modal('show')
      this.add = {
        platNumber: '',
        type: '',
        merk: '',
        amount: '',
        year: '',
        notes: '',
      }
    },
    showEdit(e) {
      this.isEdit = true
      $('#truckModal').modal('show')
      this.id = e.id
      this.add = {
        BranchId: e.BranchId,
        platNumber: e.platNumber,
        type: e.type,
        merk: e.merk,
        amount: this.formatUang(e.amount),
        year: e.year,
        notes: e.notes,
      }
    },

    async getDataTable() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/sparepart/transport",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            page: this.currentPage,
            limit: this.perPage,
            BranchId: this.find.BranchId,
            type: this.find.type, 
            merk: this.find.merk, 
            status: this.find.status, 
            keyword: this.find.keyword, 
          }
        })
        console.log(data);
        this.dataTable = data.data2.result
        this.totalBalance = data.totalBalance
        let totalPage = Math.ceil(data.data2.dataLength / this.perPage)
				this.totalPage = totalPage
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
    async addCar() {
      
      try {
        this.add.amount = this.add.amount.trim().split('.').filter(e => e != '.' ).join('')
        
        let data = await axios({
          url: '/sparepart/transport',
          method: 'post',
          data: this.add,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Berhasil', 'Data Berhasil Ditambahkan', 'success');
        this.getDataTable()
        $('#truckModal').modal('hide')
      } catch (err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    },
    async editCar() {
      
      try {
        this.add.amount = this.add.amount.trim().split('.').filter(e => e != '.' ).join('')
        
        let data = await axios({
          url: `/sparepart/transport/${this.id}`,
          method: 'put',
          data: this.add,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Berhasil', 'Data Berhasil Diubah', 'success');
        this.getDataTable()
        $('#truckModal').modal('hide')
      } catch (err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    },
    async deleteData(id) {
      
      try {
        
        let data = await axios.delete(`/sparepart/transport/${id}`, {
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Berhasil', 'Data Berhasil Dihapuskan', 'success');
        this.getDataTable()

      } catch(err) {
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');

      } 
    },
    async confirmDelete(id) {
      
      try {
        let data = await Swal.fire({
            title: 'Yakin?',
            text: 'Apakah Yakin ',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Hapus!!',
        })
        if (data.isConfirmed) {
            this.deleteData(id);
            this.getDataTable()
        } else {
          Swal.fire('Ok', 'Proses anda berhasil dibatalkan', 'success');
        }
              
              
      } catch(err) {
        
        Swal.fire('Maaf', 'Proses anda berhasil dibatalkan', 'error');
      }
    },
    currencyNumber(event, target) {
        console.log(event, "===================");
        let currentValue = event.trim().split('.').filter(e => e != '.' ).join('')

        console.log(currentValue, typeof currentValue," -----------");
        if( Number.isNaN(Number(currentValue))) {
          // this.amount = 10
          this.add.amount = this.formatUang(currentValue.split('').filter(e => !Number.isNaN( Number(e))).join(''))
          return
        }
        // currentValue = this.amount
        this.add.amount = this.formatUang(currentValue)
        // currentValue = this.amount
        // this.amount = this.amount.toLocaleString('id-ID')
        console.log(this.amount, "++++++++++++++");
    },
    formatUang(data) {
      // console.log(data);
        
      let uang = "";
        if(data) {
          data = data.toString();
          for (let i = 0; i < data.length; i++) {
            if ((data.length - i) % 3 == 0 && i !== 0) {
            uang += `.${data[i]}`;
            } else {
            uang += data[i];
            }
          }
        }
        return uang
    }, 
    async printCsv() {
      this.isLoading = true
      try {
        // alert("ok")
        let {data} = await axios({
          method: "GET",
          url: "/sparepart/amountprint",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            keyword: this.keyword,
          }
        })
        console.log(data.length);
        const dataToConvert = {
          data: data,
          filename: 'Pemakaian Sparepart',
          delimiter: ',',
        }
        csvDownload(dataToConvert)
        console.log(dataToConvert);
        this.isLoading= false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }
    },
    addPage() {
      this.currentPage = this.currentPage + 1
      this.getDataTable()
    },
    minPage() {
      this.currentPage = this.currentPage - 1
      this.getDataTable()
    },
  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }

  td {
    /* text-align: right; */
  }




</style>