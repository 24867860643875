<template>
  <div class="mainPage">
    <p id="headPage">Rekap Solar</p>
    
    <div class="filterRow">
       
      <div class="inRow">
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Cabang</p>
          <b-select v-model="findBranchId" @change="this.getDataTable">
            <option value="0" disabled>Pilih Cabang </option>
            <option v-for="e in this.branches" :key="e.id" :value="e.id"> {{e.branchName}} </option>
          </b-select>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Bulan</p>
          <b-input v-model="month" type="month" @change="this.getRekap"></b-input>
        </div>
        <!-- <div class="inCol"  id="cardFilter">
          <p id="inputTitle">Proyek</p>
          <b-select v-model="findProjectId" @change="this.getDataTable">
            <option  v-for="e in this.findProcejts" :key="e.id" :value="e.id"> {{e.projectName}} </option>
          </b-select>
        </div> -->
      </div>
    </div>
    <div id="titleRow">
      <b-button id="resetFilter" @click.prevent="resetFilter()">Reset Filter</b-button>
    </div>
    <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p> 
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th rowspan="2">Kendaraan Vendor</th>
            <th rowspan="2">Total</th>
            <th colspan="31">Tanggal</th>
          </tr>
          <tr>
            <th>1</th>
            <th>2</th>
            <th>3</th>
            <th>4</th>
            <th>5</th>
            <th>6</th>
            <th>7</th>
            <th>8</th>
            <th>9</th>
            <th>10</th>
            <th>11</th>
            <th>12</th>
            <th>13</th>
            <th>14</th>
            <th>15</th>
            <th>16</th>
            <th>17</th>
            <th>18</th>
            <th>19</th>
            <th>20</th>
            <th>21</th>
            <th>22</th>
            <th>23</th>
            <th>24</th>
            <th>25</th>
            <th>26</th>
            <th>27</th>
            <th>28</th>
            <th>29</th>
            <th>30</th>
            <th>31</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in this.dataTable" :key="e.id">
            <td> {{e.platNumber}}</td>
            <td class="text-right"> {{ getFilterRekap(0,e.id) }} </td>
            <td class="text-right"> {{ getFilterRekap(1,e.id) }} </td>
            <td class="text-right"> {{ getFilterRekap(2,e.id) }} </td>
            <td class="text-right"> {{ getFilterRekap(3,e.id) }} </td>
            <td class="text-right"> {{ getFilterRekap(4,e.id) }} </td>
            <td class="text-right"> {{ getFilterRekap(5,e.id) }} </td>
            <td class="text-right"> {{ getFilterRekap(6,e.id) }} </td>
            <td class="text-right"> {{ getFilterRekap(7,e.id) }} </td>
            <td class="text-right"> {{ getFilterRekap(8,e.id) }} </td>
            <td class="text-right"> {{ getFilterRekap(9,e.id) }} </td>
            <td class="text-right"> {{ getFilterRekap(10,e.id) }} </td>
            <td class="text-right"> {{ getFilterRekap(11,e.id) }} </td>
            <td class="text-right"> {{ getFilterRekap(12,e.id) }} </td>
            <td class="text-right"> {{ getFilterRekap(13,e.id) }} </td>
            <td class="text-right"> {{ getFilterRekap(14,e.id) }} </td>
            <td class="text-right"> {{ getFilterRekap(15,e.id) }} </td>
            <td class="text-right"> {{ getFilterRekap(16,e.id) }} </td>
            <td class="text-right"> {{ getFilterRekap(17,e.id) }} </td>
            <td class="text-right"> {{ getFilterRekap(18,e.id) }} </td>
            <td class="text-right"> {{ getFilterRekap(19,e.id) }} </td>
            <td class="text-right"> {{ getFilterRekap(20,e.id) }} </td>
            <td class="text-right"> {{ getFilterRekap(21,e.id) }} </td>
            <td class="text-right"> {{ getFilterRekap(22,e.id) }} </td>
            <td class="text-right"> {{ getFilterRekap(23,e.id) }} </td>
            <td class="text-right"> {{ getFilterRekap(24,e.id) }} </td>
            <td class="text-right"> {{ getFilterRekap(25,e.id) }} </td>
            <td class="text-right"> {{ getFilterRekap(26,e.id) }} </td>
            <td class="text-right"> {{ getFilterRekap(27,e.id) }} </td>
            <td class="text-right"> {{ getFilterRekap(28,e.id) }} </td>
            <td class="text-right"> {{ getFilterRekap(29,e.id) }} </td>
            <td class="text-right"> {{ getFilterRekap(30,e.id) }} </td>
            <td class="text-right"> {{ getFilterRekap(31,e.id) }} </td>
            
          </tr>
        </tbody>
      </table>
      <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    </div>

    <!-- modal -->
    <div class="modal" id="mainModal" tabindex="-1">
  <!-- <div class="modal-dialog"> -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Piutang</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" >&times; </span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="isEdit ? editData() : addData()">

          <div class="inRow">
            <div class="inCol">
              <p id="titleModal">Cabang</p>
              <b-select v-model="BranchId" required @change="this.getProject">
                <option value="0" disabled>Pilih Cabang </option>
                <option v-for="e in this.branches" :key="e.id" :value="e.id"> {{e.branchName}} </option>
              </b-select>
            </div>
            <div class="inCol">
              <p id="titleModal">Vendor</p>
              <b-input v-model="vendorName" type="text" required> </b-input>
            </div>
        
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary">Tambah</button>
        </div>
      </form>
      </div>
    </div>
  <!-- </div> -->
</div>
  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import moment from 'moment'
import csvDownload from 'json-to-csv-export'

export default {
  name: "Voucer",
  data() {
    return  {
      id: 0,
      projects: [],
      vendors: [],
      dataTable: [],
      types: [],
      currentPage: 1,
      perPage: 20,
			totalPage : 1,
      isLoading: false,
      totalBalance: "",
      dataBalance: [],
      
      // filter
      findBranchId: 0,
      findProjectId: 0,
      findDebtVendorId: 0,
      findDebtTypeId: 0,
      month: "",
      endDate: "",
      startDate: "",
      keyword: "",
      findVendors: [],
      findProcejts: [],

      // add
      BranchId: 0,
      vendorName : ""
    
    }
  },
  created() {
    this.$store.dispatch("getBranch")
    this.getDataTable()
    this.getType()
    this.getRekap()
  },
  computed: {
    branches() {
      return this.$store.state.branches
    }
  },  
  methods: {
    resetFilter() {
      this.findBranchId= 0
      this.findProjectId= 0
      this.findDebtVendorId= 0
      this.findDebtTypeId= 0
      this.endDate= ""
      this.startDate= ""
      this.keyword= ""
      this.getDataTable()
    },
    showAdd() {
      this.isEdit = false
      $('#mainModal').modal('show')
    },

    async getDataTable() {
      // get kendaraan
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/solar/vehicle",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            page: this.currentPage,
            limit: this.perPage,
            BranchId: this.findBranchId,
            ProjectId: this.findProjectId, 
          }
        })
        this.dataTable = data.data2.result
        this.totalBalance = data.totalBalance
        let totalPage = Math.ceil(data.data2.dataLength / this.perPage)
				this.totalPage = totalPage
        this.getRekap()
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
    async getRekap() {
      // alert("tyr")
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/solar/rekap",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            BranchId: this.findBranchId,
            month: this.month
           
          }
        })

        this.dataBalance = data
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },

    getFilterRekap(date,vehicle) {
      // untuk filter nilai di tiap tanggal
      let hasil = 0
      if(date) {
        hasil = this.dataBalance.find(e => vehicle == e.VehicleId && date == moment(e.date).format("DD") )?.total || 0
      } else {
        this.dataBalance.map(e => {
          if(e.VehicleId == vehicle) {
            hasil += e.total
          }
        })
      }

      return hasil
    },
    
    async printCsv() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/debt/balance/print",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            BranchId: this.findBranchId,
            DebtVendorId: this.findDebtVendorId, 
            DebtTypeId: this.findDebtTypeId, 
            ProjectId: this.findProjectId, 
          }
        })
        const dataToConvert = {
          data: data,
          filename: 'Saldo Piutang Krayawan / Dana gantung',
          delimiter: ',',
        }
        csvDownload(dataToConvert)
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
    addPage() {
      this.currentPage = this.currentPage + 1
      this.getDataTable()
    },
    minPage() {
      this.currentPage = this.currentPage - 1
      this.getDataTable()
    },
  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }




</style>