<template>
  <div class="camera-capture">
    <button @click="startCamera" v-if="!isCameraOn">Buka Kamera</button>
    <video ref="video" width="320" height="240" autoplay v-show="isCameraOn"></video>
    <canvas ref="canvas" width="320" height="240" style="display: none;"></canvas>
    <button @click="takePicture" v-if="isCameraOn">Ambil Foto</button>
    <img :src="photo" v-if="photo && !isCameraOn" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      photo: null,
      videoStream: null,
      isCameraOn: false,
    };
  },
  methods: {
    async startCamera() {
      try {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
          throw new Error("Browser tidak mendukung akses kamera");
        }
        
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        this.videoStream = stream;
        this.$refs.video.srcObject = stream;
        this.isCameraOn = true;
        console.log("Camera started, stream:", stream);
      } catch (error) {
        console.error("Error accessing camera:", error);

        if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
          // Show a message or alert to user indicating that they need to allow camera access
          alert("Anda perlu memberikan izin akses kamera untuk menggunakan fitur ini.");
        } else {
          alert("Tidak bisa mengakses kamera. Pastikan Anda telah memberikan izin dan coba lagi.");
        }
      }
    },
    takePicture() {
      const canvas = this.$refs.canvas;
      const video = this.$refs.video;
      const context = canvas.getContext("2d");

      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      this.photo = canvas.toDataURL("image/png");
      console.log("Picture taken, photo:", this.photo);
      this.stopCamera();
    },
    stopCamera() {
      if (this.videoStream) {
        this.videoStream.getTracks().forEach(track => track.stop());
        this.videoStream = null;
        this.isCameraOn = false;
        console.log("Camera stopped");
      }
    },
  },
  beforeDestroy() {
    this.stopCamera();
  },
};
</script>

<style>
.camera-capture {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
</style>
