<template>
  <div class="Sidebar">
    <!-- kasir -->

    <div class="menuBox" v-if="this.role == 'cashier' ||this.role == 'payment' ||this.role == 'owner' ||this.role == 'accountant'">
      <button v-b-toggle.Transaksi class="navbarButton" >Transaksi</button>
      <b-collapse id="Transaksi" class="mt-2">
        <router-link to="/voucer" v-if=" this.role == 'cashier' ||
            this.role == 'accountant' || this.role == 'owner' ">
          <div class="listMenu">
            <img src="../assets/money.png" alt="" />  
            <p class="sidebarMenu">Voucer</p>
          </div>
        </router-link>
        <router-link to="/orderpayment" v-if=" this.role == 'payment' ||
            this.role == 'accountant' || this.role == 'owner' || this.role == 'cashier' ">
          <div class="listMenu">
            <img src="../assets/money.png" alt="" />
            <p class="sidebarMenu">Pembayaran</p>
          </div>
        </router-link>
        <router-link to="/debt" v-if=" this.role == 'cashier' || this.role == 'owner' || this.role == 'accountant' " >
          <div class="listMenu">
            <img src="../assets/notes.png" alt="" />
            <p class="sidebarMenu">Mutasi Piutang</p>
          </div>
        </router-link>
        <router-link to="/debtbalance" v-if=" this.role == 'cashier' || this.role == 'owner' || this.role == 'accountant' " >
          <div class="listMenu">
            <img src="../assets/document.png" alt="" />
            <p class="sidebarMenu">Saldo Piutang</p>
          </div>
        </router-link>
        <router-link to="/debtvendor" v-if=" this.role == 'cashier' || this.role == 'owner' || this.role == 'accountant' ">
          <div class="listMenu">
            <img src="../assets/people.png" alt="" />
            <p class="sidebarMenu">Vendor</p>
          </div>
        </router-link>
      </b-collapse>
    </div>

    

    <!-- PertaShop -->

    <div class="menuBox" v-if="this.role == 'owner' ||this.role == 'pertashop'">
      <button v-b-toggle.Pertashop class="navbarButton" >Pertashop</button>
      <b-collapse id="Pertashop" class="mt-2">
        <router-link to="/pertashop" v-if=" this.role == 'pertashop' || this.role == 'owner' ">
          <div class="listMenu">
            <img src="../assets/money.png" alt="" />
            <p class="sidebarMenu">Kasir</p>
          </div>
        </router-link>
        <router-link to="/fuelmutation" v-if=" this.role == 'pertashop' || this.role == 'owner' ">
          <div class="listMenu">
            <img src="../assets/fuel.png" alt="" />
            <p class="sidebarMenu">Mutasi Stok</p>
          </div>
        </router-link>
      </b-collapse>
    </div>



    <!-- HUtang -->

    <div class="menuBox" v-if="this.role == 'adminTeknik' ||this.role == 'owner' ||this.role == 'accountant'">
      <button v-b-toggle.Hutang class="navbarButton" >Hutang</button>
      <b-collapse id="Hutang" class="mt-2">
        <router-link to="/hutang" v-if="  this.role == 'adminTeknik' || this.role == 'owner'|| this.role == 'hutang'  || this.role == 'accountant' || this.role == 'admin' " >
          <div class="listMenu">
            <img src="../assets/notes.png" alt="" />
            <p class="sidebarMenu">Mutasi Hutang</p>
          </div>
        </router-link>
        <router-link to="/hutangbalance" v-if="this.role == 'adminTeknik' || this.role == 'owner' || this.role == 'accountant'|| this.role == 'hutang' || this.role == 'admin'">
          <div class="listMenu">
            <img src="../assets/document.png" alt="" />
            <p class="sidebarMenu">Saldo Hutang</p>
          </div>
        </router-link>
        <router-link to="/hutangvendor" v-if=" this.role == 'adminTeknik' || this.role == 'owner'|| this.role == 'hutang' || this.role == 'accountant' || this.role == 'admin' ">
          <div class="listMenu">
            <img src="../assets/people.png" alt="" />
            <p class="sidebarMenu">Vendor Hutang</p>
          </div>
        </router-link>
      </b-collapse>
    </div>

    <!-- Accounting  -->
    <div class="menuBox"  v-if="this.role == 'accountant' || this.role == 'owner'">
      <button v-b-toggle.Accounting class="navbarButton"> Keuangan </button>
      <b-collapse id="Accounting" class="mt-2">
        <router-link to="/jurnal" v-if="this.role == 'accountant' || this.role == 'owner'">
          <div class="listMenu">
            <img src="../assets/journal.png" alt="" />
            <p class="sidebarMenu">Jurnal</p>
          </div>
        </router-link>
        <router-link
          to="/labarugi"
          v-if="this.role == 'accountant' || this.role == 'owner'">
          <div class="listMenu">
            <img src="../assets/profit.png" alt="" />
            <p class="sidebarMenu">Laba Rugi</p>
          </div>
        </router-link>
        <router-link
          to="/neraca"
          v-if="this.role == 'accountant' || this.role == 'owner'"
        >
          <div class="listMenu">
            <lord-icon src="https://cdn.lordicon.com/wfkxiwtw.json" trigger="hover">
            </lord-icon>
            <p class="sidebarMenu">Neraca</p>
          </div>
        </router-link>
        <router-link to="/bukubesar" v-if="this.role == 'accountant' || this.role == 'owner'">
          <div class="listMenu">
            <img src="../assets/document.png" alt="" />
            <p class="sidebarMenu">Buku Besar</p>
          </div>
        </router-link>
        <router-link to="/letter" v-if="this.role == 'accountant' || this.role == 'owner'">
          <div class="listMenu">
            <img src="../assets/document.png" alt="" />
            <p class="sidebarMenu">Persuratan</p>
          </div>
        </router-link>
        <router-link
          to="/subma"
          v-if="this.role == 'owner'" >
          <div class="listMenu">
            <lord-icon src="https://cdn.lordicon.com/huwchbks.json" trigger="hover">
            </lord-icon>
            <p class="sidebarMenu">Sub Mata Anggaran</p>
          </div>
        </router-link>
        
      </b-collapse>
    </div>

    
    <!-- Penagihan -->
    <div class="menuBox" v-if="this.role == 'Account Receivable' || this.role == 'owner'">
      <button v-b-toggle.Penagihan class="navbarButton" >Penagihan</button>
      <b-collapse id="Penagihan" class="mt-2">
        <router-link to="/receivable" v-if="this.role == 'Account Receivable' || this.role == 'owner'" >
          <div class="listMenu">
            <img src="../assets/notes.png" alt="" />
            <p class="sidebarMenu">Tagihan</p>
          </div>
        </router-link>
        <router-link to="/receivablebalancee" v-if="this.role == 'Account Receivable' || this.role == 'owner'"    >
          <div class="listMenu">
            <img src="../assets/document.png" alt="" />
            <p class="sidebarMenu">Saldo Tagihan</p>
          </div>
        </router-link>
        <router-link to="/receivablevendor" v-if="this.role == 'Account Receivable' || this.role == 'owner'">
          <div class="listMenu">
            <img src="../assets/people.png" alt="" />
            <p class="sidebarMenu">Vendor</p>
          </div>
        </router-link>
      </b-collapse>
    </div>
   

    <!-- Solar -->
    <div class="menuBox" v-if=" this.role == 'accountant' || this.role == 'owner' ||this.role == 'solar'">
      <button v-b-toggle.Solar class="navbarButton" >Solar</button>
      <b-collapse id="Solar" class="mt-2">
        <router-link to="/solar" v-if=" this.role == 'accountant' || this.role == 'owner' ||this.role == 'solar'">
          <div class="listMenu">
            <img src="../assets/fuel.png" alt="" />
            <p class="sidebarMenu">Penggunaan Solar</p>
          </div>
        </router-link>
        <router-link to="/rekapsolar" v-if=" this.role == 'accountant' || this.role == 'owner' || this.role == 'solar'">
          <div class="listMenu">
            <img src="../assets/notes.png" alt="" />
            <p class="sidebarMenu">Rekap Solar</p>
          </div>
        </router-link>
        <router-link to="/solarbalance" v-if=" this.role == 'accountant' || this.role == 'owner' || this.role == 'solar'">
          <div class="listMenu">
            <img src="../assets/notes.png" alt="" />
            <p class="sidebarMenu">Saldo Solar</p>
          </div>
        </router-link>
        <p id="titleMenu" v-if=" this.role == 'accountant' || this.role == 'owner' || this.role == 'solar'">Dana Gantung Solar</p>
        <router-link to="/dgsolar" v-if=" this.role == 'solar' || this.role == 'owner' || this.role == 'accountant' " >
          <div class="listMenu">
            <img src="../assets/notes.png" alt="" />
            <p class="sidebarMenu">Mutasi</p>
          </div>
        </router-link>
        <router-link to="/solardgbalance" v-if=" this.role == 'solar' || this.role == 'owner' || this.role == 'accountant' " >
          <div class="listMenu">
            <img src="../assets/document.png" alt="" />
            <p class="sidebarMenu">Saldo Dana Gantung</p>
          </div>
        </router-link>
        <router-link to="/driver" v-if=" this.role == 'solar' || this.role == 'owner' || this.role == 'accountant' ">
          <div class="listMenu">
            <img src="../assets/people.png" alt="" />
            <p class="sidebarMenu">Driver</p>
          </div>
        </router-link>
      </b-collapse>
    </div>


    <!-- Retase -->
    <div class="menuBox" v-if=" this.role == 'accountant'|| this.role == 'retase' || this.role == 'owner' ||this.role == 'solar'">
      <button v-b-toggle.Retase class="navbarButton"  >Retase</button>
      <b-collapse id="Retase" class="mt-2">
        <router-link to="/retase" v-if=" this.role == 'accountant'|| this.role == 'retase' || this.role == 'owner' ||this.role == 'solar'" >
          <div class="listMenu">
            <img src="../assets/fuel.png" alt="" />
            <p class="sidebarMenu">Retase</p>
          </div>
        </router-link>
        <router-link to="/fare" v-if=" this.role == 'accountant' || this.role == 'owner' || this.role == 'retase'">
          <div class="listMenu">
            <img src="../assets/notes.png" alt="" />
            <p class="sidebarMenu">Daftar Tarif</p>
          </div>
        </router-link>
      </b-collapse>
    </div>

    
    <!-- Material -->
    <div class="menuBox" v-if=" this.role == 'accountant' || this.role == 'owner' || this.role == 'material' || this.role == 'gudang' ">
      <button v-b-toggle.Material class="navbarButton" >Material</button>
      <b-collapse id="Material" class="mt-2">
        <router-link to="/material" v-if="this.role == 'accountant' ||this.role == 'owner' ||this.role == 'material' ||this.role == 'gudang'">
          <div class="listMenu">
            <img src="../assets/brick.png" alt="" />
            <p class="sidebarMenu">Material</p>
          </div>
        </router-link>
        <router-link to="/materialbalance" v-if="this.role == 'accountant' ||this.role == 'owner' ||this.role == 'material' ||this.role == 'gudang'">
          <div class="listMenu">
            <img src="../assets/inventory.png" alt="" />
            <p class="sidebarMenu">Saldo Material</p>
          </div>
        </router-link>
        <router-link to="/materialtype" v-if=" this.role == 'accountant' || this.role == 'owner' || this.role == 'material' ">
          <div class="listMenu">
            <img src="../assets/tool.png" alt="" />
            <p class="sidebarMenu">Jenis Material</p>
          </div>
        </router-link>
      </b-collapse>
    </div>

    <!-- Produksi -->
    <div class="menuBox" v-if="this.role == 'accountant' ||this.role == 'owner' ||this.role == 'material' ||  this.role == 'gudang'">
      <button v-b-toggle.Produksi class="navbarButton" >Produksi</button>
      <b-collapse id="Produksi" class="mt-2">
        <router-link to="/productmuatation" v-if="this.role == 'accountant' ||this.role == 'owner' ||this.role == 'material' ||  this.role == 'gudang'">
          <div class="listMenu">
            <img src="../assets/transaction.png" alt="" />
            <p class="sidebarMenu">Mutasi Produk</p>
          </div>
        </router-link>
        <router-link to="/productbalance" v-if="this.role == 'accountant' ||this.role == 'owner' ||this.role == 'material' ||  this.role == 'gudang'">
          <div class="listMenu">
            <img src="../assets/inventory.png" alt="" />
            <p class="sidebarMenu">Saldo Produk</p>
          </div>
        </router-link>
        <router-link
          to="/producttype"
          v-if="
            this.role == 'accountant' ||
            this.role == 'owner' ||
            this.role == 'material' ||  this.role == 'adminTeknik'
          "
        >
          <div class="listMenu">
            <img src="../assets/inventory.png" alt="" />
            <p class="sidebarMenu">Jenis Produk</p>
          </div>
        </router-link>
      </b-collapse>
    </div>

    <!-- Mixer -->
    <div class="menuBox" v-if=" this.role == 'accountant' || this.role == 'owner' || this.role == 'material' || this.role == 'gudang' ">
      <button v-b-toggle.Mixer class="navbarButton" >Mixer</button>
      <b-collapse id="Mixer" class="mt-2">
        <router-link to="/mixer" v-if="this.role == 'owner' ||this.role == 'material' ||this.role == 'gudang'">
          <div class="listMenu">
            <img src="../assets/mixer.png" alt="" />
            <p class="sidebarMenu">Ready Mix</p>
          </div>
        </router-link>
        <router-link to="/mixerorder" v-if="this.role == 'accountant' ||this.role == 'owner' ||this.role == 'material' ||this.role == 'gudang'">
          <div class="listMenu">
            <img src="../assets/mixer.png" alt="" />
            <p class="sidebarMenu">Order Mixer</p>
          </div>
        </router-link>
        <router-link to="/typemixer" v-if="this.role == 'accountant' ||this.role == 'owner' ||this.role == 'material' ||this.role == 'gudang'">
          <div class="listMenu">
            <img src="../assets/inventory.png" alt="" />
            <p class="sidebarMenu">Jenis Ready Mix</p>
          </div>
        </router-link>
        
      </b-collapse>
    </div>

    <!-- Rent -->
    <div class="menuBox" v-if=" this.role == 'accountant' || this.role == 'owner' || this.role == 'material' || this.role == 'gudang' ">
      <button v-b-toggle.Rental class="navbarButton"  >Rental</button>
      <b-collapse id="Rental" class="mt-2">
        <router-link to="/rent" v-if="this.role == 'owner' ||this.role == 'material' ||this.role == 'gudang'">
          <div class="listMenu">
            <img src="../assets/excavator.png" alt="" />
            <p class="sidebarMenu">Mutasi Rental Alat</p>
          </div>
        </router-link>
        <router-link to="/rentorder" v-if="this.role == 'accountant' ||this.role == 'owner' ||this.role == 'material' ||this.role == 'gudang'">
          <div class="listMenu">
            <img src="../assets/excavator.png" alt="" />
            <p class="sidebarMenu">Pemesanan Alat</p>
          </div>
        </router-link>
        <router-link to="/typerent" v-if="this.role == 'accountant' ||this.role == 'owner' ||this.role == 'material' ||this.role == 'gudang'">
          <div class="listMenu">
            <img src="../assets/inventory.png" alt="" />
            <p class="sidebarMenu">Tarif</p>
          </div>
        </router-link>
        
      </b-collapse>
    </div>

    <!-- Sparepart -->
    <div class="menuBox" v-if=" this.role == 'accountant' ||this.role == 'owner' || this.role == 'sparepart'">
      <button v-b-toggle.Sparepart class="navbarButton" >Sparepart</button>
      <b-collapse id="Sparepart" class="mt-2">
        
        <router-link to="/poorder" v-if=" this.role == 'accountant' || this.role == 'owner' || this.role == 'sparepart'">
          <div class="listMenu">
            <img src="../assets/document.png" alt="" />
            <p class="sidebarMenu">Pencatatan PO</p>
          </div>
        </router-link>
        <router-link to="/sparepartmutation" v-if=" this.role == 'accountant' || this.role == 'owner' || this.role == 'sparepart'">
          <div class="listMenu">
            <img src="../assets/tool.png" alt="" />
            <p class="sidebarMenu">Mutasi Sparepart</p>
          </div>
        </router-link>
        <router-link to="/subbill" v-if=" this.role == 'accountant' || this.role == 'owner' ||  this.role == 'sparepart' ">
          <div class="listMenu">
            <img src="../assets/inventory.png" alt="" />
            <p class="sidebarMenu">History Sparepart</p>
          </div>
        </router-link>
        <router-link to="/sparepartbalance" v-if=" this.role == 'sparepart' || this.role == 'owner' " >
          <div class="listMenu">
            <img src="../assets/inventory.png" alt="" />
            <p class="sidebarMenu">Saldo Sparepart</p>
          </div>
        </router-link>
        <router-link to="/spareparttype" v-if="this.role == 'owner' ||this.role == 'sparepart' ||  this.role == 'adminTeknik'">
          <div class="listMenu">
            <img src="../assets/inventory.png" alt="" />
            <p class="sidebarMenu">Jenis Sparepart</p>
          </div>
        </router-link>
      </b-collapse>
    </div>

    <!-- APD -->
    <div class="menuBox" v-if=" this.role == 'accountant' ||this.role == 'owner' || this.role == 'apd'">
      <button v-b-toggle.APD class="navbarButton" >APD</button>
      <b-collapse id="APD" class="mt-2">
        <router-link to="/apdmutation" v-if=" this.role == 'accountant' || this.role == 'owner' || this.role == 'apd'">
          <div class="listMenu">
            <img src="../assets/transaction.png" alt="" />
            <p class="sidebarMenu">Mutasi APD</p>
          </div>
        </router-link>
        <router-link to="/apdSubBill" v-if=" this.role == 'accountant' || this.role == 'owner' ||  this.role == 'apd' ">
          <div class="listMenu">
            <img src="../assets/document.png" alt="" />
            <p class="sidebarMenu">History APD</p>
          </div>
        </router-link>
        <router-link to="/apdbalance" v-if=" this.role == 'apd' || this.role == 'owner' " >
          <div class="listMenu">
            <img src="../assets/inventory.png" alt="" />
            <p class="sidebarMenu">Saldo APD</p>
          </div>
        </router-link>
        <router-link to="/apdtype" v-if="this.role == 'owner' ||this.role == 'apd'">
          <div class="listMenu">
            <img src="../assets/safety.png" alt="" />
            <p class="sidebarMenu">Jenis APD</p>
          </div>
        </router-link>
      </b-collapse>
    </div>


    <!-- Truck -->
    <div class="menuBox" v-if="this.role == 'owner'||  this.role == 'transport'  ">
      <button v-b-toggle.Kendaraan class="navbarButton" >Kendaraan</button>
      <b-collapse id="Kendaraan" class="mt-2">
        <router-link to="/transport" v-if="this.role == 'owner' ||  this.role == 'transport' ">
          <div class="listMenu">
            <img src="../assets/transport.png" alt="" />
            <p class="sidebarMenu">Daftar Kendaraan</p>
          </div>
        </router-link>
        <router-link to="/shippungdocument" v-if="this.role == 'owner' ||  this.role == 'transport' ">
          <div class="listMenu">
            <img src="../assets/document.png" alt="" />
            <p class="sidebarMenu">Surat Jalan</p>
          </div>
        </router-link>        
      </b-collapse>
    </div>

    <!-- Inventory -->
    <div class="menuBox" v-if="this.role == 'accountant' ||this.role == 'owner' ||this.role == 'inventory'">
      <button v-b-toggle.Inventory class="navbarButton" >Inventory</button>
      <b-collapse id="Inventory" class="mt-2">
        <router-link
          to="/inventory"
          v-if="
            this.role == 'owner' || this.role == 'inventory'
          "
        >
          <div class="listMenu">
            <img src="../assets/inventory.png" alt="" />
            <p class="sidebarMenu">Inventory</p>
          </div>
        </router-link>
        <router-link to="/Inventorymutation" v-if="this.role == 'owner' ||  this.role == 'adminTeknik' || this.role == 'inventory'">
          <div class="listMenu">
            <img src="../assets/inventory.png" alt="" />
            <p class="sidebarMenu">Inventory Mutasi</p>
          </div>
        </router-link>
      </b-collapse>
    </div>

    <div class="menuBox" v-if="this.role == 'owner' ||  this.role == 'retase'">
      <button v-b-toggle.Mobile class="navbarButton" >Mobile</button>
      <b-collapse id="Mobile" class="mt-2">
        <router-link to="/mobileview" v-if="this.role == 'owner'||  this.role == 'retase'">
          <div class="listMenu">
            <img src="../assets/mobile.png" alt="" />
            <p class="sidebarMenu">Mobile</p>
          </div>
        </router-link>     
      </b-collapse>
    </div>

    <div class="menuBox" v-if="this.role == 'owner'">
      <button v-b-toggle.Database class="navbarButton" >Database</button>
      <b-collapse id="Database" class="mt-2">
        <router-link to="/branch" v-if="this.role == 'owner'">
          <div class="listMenu">
            <lord-icon src="https://cdn.lordicon.com/jqnthkou.json" trigger="hover">
            </lord-icon>
            <p class="sidebarMenu">Cabang</p>
          </div>
        </router-link>
        <router-link to="/project" v-if="this.role == 'owner'">
          <div class="listMenu">
            <lord-icon src="https://cdn.lordicon.com/qhgmphtg.json" trigger="hover">
            </lord-icon>
            <p class="sidebarMenu">Proyek</p>
          </div>
        </router-link>
        <router-link to="/user" v-if="this.role == 'owner'">
          <div class="listMenu">
            <img src="../assets/people.png" alt="" />
            <p class="sidebarMenu">User</p>
          </div>
        </router-link>
      </b-collapse>
    </div>

  </div>
</template>

<script>
export default {
  name: "Sidebar",
  computed: {
    role() {
      return localStorage.getItem("role");
    },
  },
};
</script>

<style scoped>
.Sidebar {
  padding-left: 5px;
  padding-top: 16px;
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: #f3f3f3;
  font-weight: 500;
  /* background-color: rgb(120, 39, 39); */
}
img {
  margin: 0 4px;
  max-width: 16px;
}
.sidebarMenu {
  color: black;
  text-decoration: none;
  margin-left: 0;
}
router-link a:hover {
  text-decoration: none;
}
p {
  font-size: 11px;
  margin: 0px;
  align-self: center;
}
#titleMenu {
  font-weight: 800;
  margin-top: 10px;
  align-self: flex-start;
  padding-left: 10px;
}
.Sidebar a:hover {
  text-decoration: none;
}

/* .Sidebar a:active {
  color: red;
} */
.listMenu {
  display: flex;
  margin-bottom: 2px;
}
lord-icon {
  width: 24px;
  height: 24px;
}

.router-link-active {
  background-color: #bee0ff;
  font-weight: 800;
  border-radius:10px 0px 0px 10px ;
}
router-link a:active {
  text-decoration: none;
}
button {
  /* background-color: transparent; */
  /* color: #2181DD; */
  border: none;
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  padding-left: 0.5rem;
}

.menuBox {
  text-align: left;
  margin-bottom: 0.5rem;
  color: #2181DD;
}
</style>
