<template>
  <div class="mainPage">
    <p id="headPage">SUB MATA ANGGARAN</p>
    <div id="titleRow">
      <b-button id="addModal" @click.prevent="showAdd()">Tambah Data</b-button>
    </div>

    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover mb-2" id="tableData">
        <thead>
          <tr>
            <th>Id</th>
            <th>Mata Anggaran</th>
            <th>Nama Sub Mata Anggaran</th>
            <th>Saldo Normal</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in this.dataTable" :key="e.id">
            <td>{{ e.id }}</td>
            <td> {{e.MataAnggaran.mataAnggaranName}} </td>
            <td> {{e.subMataAnggaranName}} </td>
            <td> {{e.NormalBalance.balancename}} </td>
            <td id="action" class="text-center" >
                <button @click.prevent="showEdit(e)" title="Edit" >
                  <lord-icon
                    src="https://cdn.lordicon.com/nxaaasqe.json"
                    trigger="hover">
                  </lord-icon>
                </button>
                <button @click.prevent="confirmDelete(e.id)" title="Hapus">
                    <lord-icon
                        src="https://cdn.lordicon.com/gsqxdxog.json"
                        trigger="hover">
                    </lord-icon>
                </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- modal -->
  <div class="modal" id="mainModal" tabindex="-1">
  <!-- <div class="modal-dialog"> -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Sub Mata Anggaran</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" >&times; </span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="isEdit ? editData() : addData()">
        <div class="inRow">
          <div class="inCol">
            <p id="inputTitle">MataAnggaran</p>
            <b-select required v-model="MataAnggaranId">
              <option v-for="e in mataanggarans" :key="e.id" :value="e.id">{{ e.mataAnggaranName }}</option>
            </b-select>
          </div>
          <div class="inCol">
            <p id="inputTitle">Saldo Normal</p>
            <b-select required v-model="NormalBalanceId">
              <option value="11">Debit</option>
              <option value="12">Kredit</option>
            </b-select>
          </div>
          <div class="inCol">
            <p id="inputTitle">Nama Sub Mata Anggaran</p>
            <b-input type="text" v-model="subMataAnggaranName" required></b-input>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary">Tambah</button>
        </div>
      </form>
      </div>
    </div>
  <!-- </div> -->
</div>

<!-- Vehicle -->

  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import moment from 'moment'
import csvDownload from 'json-to-csv-export'
export default {
  name: "Voucer",
  data() {
    return  {
      id: 0,
      dataTable: [],
      mataanggarans: [],
      kelMas: [],
      isLoading: false,
      isEdit: false,
      
      // filter
      findMataANggaranId: 0,

    
      // modal
      MataAnggaranId: 0,
      subMataAnggaranName: "",
      NormalBalanceId: 11,

      platNumber: "",
    }
  },
  created() {
    this.$store.dispatch("getBranch")
    this.getDataTable()
    this.getKelMa()
    this.getMataAnggaran()
  },
  computed: {
    branches() {
      return this.$store.state.branches
    }
  },  
  methods: {

    showAdd() {
      this.isEdit = false
      this.MataAnggaranId= 0,
      this.subMataAnggaranName= "",
      this.isEdit = false
      $('#mainModal').modal('show')
    },
    showEdit(e) {
      this.getProject()
      this.id = e.id
      this.isEdit = true
      $('#mainModal').modal('show')
     this.BranchId=  e.BranchId
     this.date=  e.date
     this.notes=  e.notes
     this.amount=  e.amount
     this.ProjectId= e.ProjectId
     this.DebtVendorId= e.DebtVendorId
     this.transactionType= e.transactionType
     this.DebtTypeId= e.DebtTypeId
    },
    async getDataTable() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/database/subma",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            startDate: this.startDate,
            endDate: this.endDate,
            page: this.currentPage,
            limit: this.perPage,
            BranchId: this.findBranchId,

          }
        })
        this.dataTable = data
        // this.dataTable = data.data2.result
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },

    async addData() {

      try {
        
        let proses = await axios({
          method: "POST",
          url: "/database/subma",
          data: {
            MataAnggaranId : this.MataAnggaranId,
            NormalBalanceId : this.NormalBalanceId,
            subMataAnggaranName : this.subMataAnggaranName,
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.subMataAnggaranName = "",
        this.NormalBalanceId = 11,
        this.MataAnggaranId = "",
        $('#mainModal').modal('hide')
        Swal.fire('Berhasil', 'Data berhasil diproses', 'success');
        this.vehicle = data
      } catch(err) {
        console.log(err);
        this.isLoading = false
        console.log(err);
      }

    },

    async getKelMa() {

      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/database/kelompokma",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.kelMas = data
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }
    },

    async getMataAnggaran() {

      try {
        let {data} = await axios({
          method: "GET",
          url: "/database/mataanggaran",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.mataanggarans = data
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }
    },

    filterMa(kelmaId) {

    },

    async printCsv() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/debt/print",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            startDate: this.startDate,
            endDate: this.endDate,
            BranchId: this.findBranchId,
            DebtVendorId: this.findDebtVendorId, 
            DebtTypeId: this.findDebtTypeId, 
            ProjectId: this.findProjectId, 
          }
        })
        const dataToConvert = {
          data: data,
          filename: 'daftar absen per pegawai',
          delimiter: ',',
        }
        csvDownload(dataToConvert)
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY")
    },



    async editData(e) {

      try {
        let payload = await {
          BranchId:  this.BranchId,
          date:  this.date,
          notes:  this.notes,
          amount:  this.amount,
          ProjectId: this.ProjectId,
          DebtVendorId: this.DebtVendorId,
          transactionType: this.transactionType,
          DebtTypeId: this.DebtTypeId,
        }

        let data2 = await axios({
          method: "put",
          url: `/debt/${this.id}`,
          data: payload,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        $('#mainModal').modal('hide')
        this.getDataTable()
        this.BranchId=  0
        this.date=  ""
        this.ProjectId=  ""
        this.DebtVendorId=  ""
        this.notes=  ""
        this.transactionType=  ""
        this.DebtTypeId=  ""
        this.amount=  0
        this.id=  0
        Swal.fire('Berhasil', 'Data Berhasil Ditambahkan', 'success');
        
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    }, 

    async deleteData(id) {
      
      try {
        
        let data = await axios.delete(`/database/subma/${id}`, {
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Berhasil', 'Data Berhasil Dihapuskan', 'success');

      } catch(err) {
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');

      } 
    },
    async confirmDelete(id) {
      
      try {
        let data = await Swal.fire({
            title: 'Yakin?',
            text: 'Apakah Yakin ',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Hapus!!',
        })
        if (data.isConfirmed) {
            this.deleteData(id);
            this.getDataTable()
        } else {
                Swal.fire('Ok', 'Proses anda berhasil dibatalkan', 'error');
              }
              
              
            } catch(err) {
              
              Swal.fire('Maaf', 'Proses anda berhasil dibatalkan', 'error');
      }
    },
    addPage() {
      this.currentPage = this.currentPage + 1
      this.getDataTable()
    },
    minPage() {
      this.currentPage = this.currentPage - 1
      this.getDataTable()
    },
  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }




</style>