<template>
  <div class="mainPage">
    <p id="headPage">Vendor</p>
    
    <div class="filterRow">
       
      <div class="inRow">
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Cabang</p>
          <b-select v-model="findBranchId" @change="this.getDataTable">
            <option value="0" disabled>Pilih Cabang </option>
            <option v-for="e in this.branches" :key="e.id" :value="e.id"> {{e.branchName}} </option>
          </b-select>
        </div>
        <!-- <div class="inCol"  id="cardFilter">
          <p id="inputTitle">Proyek</p>
          <b-select v-model="findProjectId" @change="this.getDataTable">
            <option  v-for="e in this.findProcejts" :key="e.id" :value="e.id"> {{e.projectName}} </option>
          </b-select>
        </div> -->
      </div>
    </div>
    <div id="titleRow">
      <b-button id="addModal" @click.prevent="showAdd()">Tambah</b-button>
      <b-button id="resetFilter" @click.prevent="resetFilter()">Reset Filter</b-button>
    </div>
    <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p> 
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>Nama Vendor</th>
            <th>Cabang</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in this.dataTable" :key="e.id">
            <td> {{e.vendorName}}</td>
            <td>{{e.Branch.branchName}} </td>
            <td>
              
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    </div>

    <!-- modal -->
    <div class="modal" id="mainModal" tabindex="-1">
  <!-- <div class="modal-dialog"> -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Piutang</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" >&times; </span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="isEdit ? editData() : addData()">

          <div class="inRow">
            <div class="inCol">
              <p id="titleModal">Cabang</p>
              <b-select v-model="BranchId" required @change="this.getProject">
                <option value="0" disabled>Pilih Cabang </option>
                <option v-for="e in this.branches" :key="e.id" :value="e.id"> {{e.branchName}} </option>
              </b-select>
            </div>
            <div class="inCol">
              <p id="titleModal">Vendor</p>
              <b-input v-model="vendorName" type="text" required> </b-input>
            </div>
        
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary">Tambah</button>
        </div>
      </form>
      </div>
    </div>
  <!-- </div> -->
</div>
  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import moment from 'moment'
import csvDownload from 'json-to-csv-export'

export default {
  name: "Voucer",
  data() {
    return  {
      id: 0,
      projects: [],
      vendors: [],
      dataTable: [],
      types: [],
      currentPage: 1,
      perPage: 20,
			totalPage : 1,
      isLoading: false,
      totalBalance: "",
      
      // filter
      findBranchId: 0,
      findProjectId: 0,
      findDebtVendorId: 0,
      findDebtTypeId: 0,
      endDate: "",
      startDate: "",
      keyword: "",
      findVendors: [],
      findProcejts: [],

      // add
      BranchId: 0,
      vendorName : ""
    
    }
  },
  created() {
    this.$store.dispatch("getBranch")
    this.getDataTable()
    this.getType()
  },
  computed: {
    branches() {
      return this.$store.state.branches
    }
  },  
  methods: {
    resetFilter() {
      this.findBranchId= 0
      this.findProjectId= 0
      this.findDebtVendorId= 0
      this.findDebtTypeId= 0
      this.endDate= ""
      this.startDate= ""
      this.keyword= ""
      this.getDataTable()
    },
    showAdd() {
      this.isEdit = false
      $('#mainModal').modal('show')
    },

    async getDataTable() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/debt/vendor",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            page: this.currentPage,
            limit: this.perPage,
            BranchId: this.findBranchId,
            ProjectId: this.findProjectId, 
          }
        })
        this.dataTable = data.data2.result
        this.totalBalance = data.totalBalance
        let totalPage = Math.ceil(data.data2.dataLength / this.perPage)
				this.totalPage = totalPage
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },

    async addData() {
      
      try {
        let data = await axios({
          url: '/debt/vendor',
          method: 'post',
          data: {
            BranchId : this.BranchId,
            vendorName : this.vendorName,
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Berhasil', 'Data Berhasil Ditambahkan', 'success');
        this.getDataTable()
        $('#mainModal').modal('hide')
      } catch (err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    },
    async getFIndProject() {
      try {
        let project = await axios({
          url: "/user/project",
          params: {
           BranchId:  this.findBranchId
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.findProcejts = project.data
      } catch(err) {
        console.log(err);
      }
    },  
    async printCsv() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/debt/balance/print",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            BranchId: this.findBranchId,
            DebtVendorId: this.findDebtVendorId, 
            DebtTypeId: this.findDebtTypeId, 
            ProjectId: this.findProjectId, 
          }
        })
        const dataToConvert = {
          data: data,
          filename: 'Saldo Piutang Krayawan / Dana gantung',
          delimiter: ',',
        }
        csvDownload(dataToConvert)
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
    addPage() {
      this.currentPage = this.currentPage + 1
      this.getDataTable()
    },
    minPage() {
      this.currentPage = this.currentPage - 1
      this.getDataTable()
    },
  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }




</style>