<template>
  <div class="mainPage">
    <div class="loginBox">
      <div class="inCol">
        <img src="../assets/logoKjk.png" alt="">

      </div>
      <!-- <p id="headTitle">SIKU RAJA</p>
      <p id="title">SISTEM KEUANGAN KURNIA JAYA</p> -->
      <form @submit.prevent="login">
        <!-- <p id="inputTitle">Username</p> -->
        <input type="text" required v-model="username" placeholder="Username">
        <!-- <p id="inputTitle">Password</p> -->
        <input type="password" v-model="password" required placeholder="Password">
        <button type="submit">LOGIN</button>
        <!-- <p id="copyright">Honesty and hard work are the two main pillars of character. - Mahatma Gandhi</p> -->
      </form>
    </div>
  </div>
</template>

<script>
import axios from '../API/axios';
import Swal from "sweetalert2";
import moment from 'moment';
import '../assets/Miraculous&Christmas.ttf'
export default {
  name: "LoginPage",
  data() {
    return {
      username: '',
      password: "",
      number: 0,
      numberFormatted: '0',
      
    };
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin;
    },
  },
  mounted() {
    this.createSnowflakes();
  },
  methods: {
    async login() {
      try {
        let data = await axios({
          method: 'post',
          url: "/user/login",
          data: {
            username: this.username,
            password: this.password,
          },
        });
        let date = moment(new Date()).format("YYYY-MM-DD");
        console.log(data, "data");
        localStorage.setItem('access_token', data.data.access_token);
        localStorage.setItem('role', data.data.role);
        localStorage.setItem('username', data.data.username);
        localStorage.setItem('date', date);
        Swal.fire("Semangat Pagi", "Semangat Pagi, Semangat Bekerja", "success");
        this.$store.commit("setIsLogin", true);
        this.$store.dispatch('getLocalStorage');
        this.$router.push("/");
      } catch (err) {
        Swal.fire("Maaf", "Password/username anda salah", "error");
      }
    },
    createSnowflakes() {
      const numFlakes = 50;
      const container = document.getElementById('snowflakes');
      const flakes = [];

      for (let i = 0; i < numFlakes; i++) {
        const flake = document.createElement('div');
        flake.className = 'snowflake';
        flakes.push(flake);
        container.appendChild(flake);
      }

      function snowfall() {
        flakes.forEach((flake, i) => {
          const x = Math.random() * window.innerWidth;
          const y = Math.random() * window.innerHeight;
          const size = Math.random() * 3 + 1;

          flake.style.left = x + 'px';
          flake.style.top = y + 'px';
          flake.style.width = size + 'px';
          flake.style.height = size + 'px';

          const animationDuration = Math.random() * 10 + 5 + 's';
          flake.style.animation = `fall ${animationDuration} linear infinite`;

          if (i % 2 === 0) {
            flake.style.animationDelay = '-' + animationDuration / 2;
          }
        });
      }

      snowfall();
    },
  },
};
</script>

<style scoped>
.mainPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: url(../assets/kjkt.jpeg);
  /* Mengubah ke contain agar gambar mempertahankan aspeknya */
  background-repeat: repeat; /* Mengulang gambar jika tidak cukup besar */
}

p #marry {
  display:block;
}
.loginBox {
  /* background-color: hsla(0, 0%, 100%, 0.5); */
  background-color: #fff;
  border-radius: 20px;
  padding: 20px 40px 32px 40px;
  /* min-width: 40rem; */
  width: 25rem;
}
img {
  margin-bottom: 32px;
 max-width: 8rem;
}
#headTitle {

  color: #FFFFFF;
  font-size: 28px;
  margin: 0px;
  font-weight: 600;
  text-align: left;
}

#title {
  text-align: left;
  font-size: 16px;
  color: #FFFFFF;
  margin: 0px;
  margin-bottom: 10px;
  margin-bottom: 70px;
  font-weight: 500;
}

#inputTitle {
  font-family: 'Mountains of Christmas', serif;
  color: #e8e8e8;
  text-align: left;
  font-size: 16px;
  font-weight: 800;
  margin: 0;
}
.inCol {
    align-items: center;
    /* justify-content: center; */
    /* text-align: center; */
    display: flex;
    flex-direction: column;
}
input  {
  margin-bottom: 5px;
  font-size: 16px;
  text-align: center;
  padding: 6px 20px;
  border-radius: 8px;
  border: 1px rgb(188 183 164) solid;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  background-color: rgba(235, 231, 231, 0.5);
}
input:active {

}

button {
  margin-top: 4px;
    padding: 4px 0;
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    background-color: #EA9023;
    border-radius: 8px;
    border: none;
}

button:hover {
  /* color: #ededed;
  background-color: #EA9023; */
  color: #d73616; 
  background-color: transparent;
  font-weight: 800;
}

/* button a: */

form {
  display: flex;
  flex-direction: column;
}

#copyright {
  
  margin-top: 4px;
  color: #0d0f0e;
  font-weight: 500;
  font-size: 14px;
}

/* Snowflakes (continued) */
.snowflakes {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}




</style>
