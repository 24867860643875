<template>
  <div class="mainPage">
    <p id="headPage">Jurnal</p>
    <div class="filterRow">
       
      <div class="inRow">
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Cabang</p>
          <b-select v-model="findBranchId" @change="this.getDataTable">
            <option value="0" disabled>Pilih Cabang </option>
            <option v-for="e in this.branches" :key="e.id" :value="e.id"> {{e.branchName}} </option>
          </b-select>
        </div>
          <div class="inCol" id="cardFilter">
            <p id="inputTitle" >Tanggal Mulai</p>
            <b-input type="date"  v-model="startDate"  @change="this.getDataTable"></b-input>
          </div>
          <div class="inCol" id="cardFilter">
            <p id="inputTitle">Tanggal Selesai</p>
            <b-input type="date" v-model="endDate"  @change="this.getDataTable"></b-input>
        </div>
          <div class="inCol" id="cardFilter">
            <p id="inputTitle">Keyword</p>
            <b-input type="text" v-model="keyword"  @change="this.getDataTable"></b-input>
        </div>
      </div>
    </div>
    <div id="titleRow">
      <router-link to="/addjurnal" v-if="role == 'accountant'" >
        <b-button id="addModal" >Tambah</b-button>
      </router-link>
      <b-button id="resetFilter" @click.prevent="resetFilter()">Reset Filter</b-button>
    </div>
    <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p> 
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>#</th>
            <th>Cabang</th>
            <th>Tanggal</th>
            <th>No.Reff</th>
            <th>Keterangan</th>
            <!-- <th colspan="2">Aksi</th> -->
            <th v-if="role == 'accountant'">Aksi</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(e,index) in this.dataTable" :key="e.id">
            <td>{{ index+1 }}</td>
            <td>{{e.Branch.branchName}} </td>
            <td>{{formatDate(e.date)}} </td>
            <td> {{e.refNo}} </td>
            <td> {{e.notes}} </td>
            <!-- <td class="uang"> {{ formatUang(e.amount)}} </td> -->
            <td id="action" class="text-center"  style="flex-wrap: nowrap;display: flex;">
                <!-- <button @click.prevent="showEdit(e)" title="Edit" > -->
                <button @click.prevent="seeJurnal(e.id)" title="See Jurnal" >
                  <img src="../assets/u_list-ul.png" alt="">
                </button>
                <button @click.prevent="getOneJurnal(e.id)" title="Edit" >
                  <img src="../assets/edit.png" alt="">
                </button>
                <button @click.prevent="confirmDelete(e.id)" title="Hapus"  >
                  <img src="../assets/trash.png" alt="">
                </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    </div>

    <!-- modal -->
    <div class="modal" id="mainModal">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Jurnal</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" >&times; </span>
            </button>
          </div>
          <div class="modal-body">
            <table class="table table-borderless" id="printTable">
              <tr>
                <td id="keyTable">Tanggal</td>
                <td id="titikDua">:</td>
                <td>{{ formatDate(jurnal.date) }}</td>
              </tr>
              <tr >
                <td id="keyTable" >Keterangan</td>
                <td id="titikDua">:</td>
                <td>{{ jurnal.notes }}</td>
              </tr>
            </table>
            <hr>
            <table class="table table-bordered" id="printTable">
              <thead>
                <tr>
                  <th>Akun</th>
                  <th>Project</th>
                  <th>Debet</th>
                  <th>Kredit</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="e in jurnal.SubJurnals" :key="e.id">
                  <td>{{ e.SubMataAnggaran.subMataAnggaranName }}</td>
                  <td>{{ e.Project.projectName }}</td>
                  <td class="text-right" v-if="e.NormalBalanceId == 11">{{ formatUang(e.amount) }}</td>
                  <td class="text-right" v-if="e.NormalBalanceId == 11">-</td>
                  
                  <td class="text-right" v-if="e.NormalBalanceId == 12">-</td>
                  <td class="text-right" v-if="e.NormalBalanceId == 12">{{ formatUang(e.amount) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    <!-- modal -->


  <!--  -->
  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import moment from 'moment'
import csvDownload from 'json-to-csv-export'
export default {
  name: "Voucer",
  data() {
    return  {
      jurnal: {},
      projects: [],
      dataTable: [],
      currentPage: 1,
      perPage: 40,
			totalPage : 2,
      isLoading: false,
      isEdit: false,
      kelma: [],
      ma: [],
      subma: [],
      notes: '',
      BranchId: 0,
      // filter
      findBranchId: 0,
      findJurnalKategori: "",
      endDate: "",
      startDate: "",
      keyword: "",
    
      // modal
      modal: {
        id: 0,
        date: '',
        notes: '',
        ProjectId: 0
      },
      subJurnals: [{
        id: 1,
        KelompokMaId: 0,
        MataAnggaranId: 0,
        SubMataAnggaranId: 0,
        NormalBalanceId : 0,
        ProjectId: 0,
        amount: 0
      }],
    }
  },
  created() {
    this.$store.dispatch("getBranch")
    this.getDataTable()
    this.getMataAnggaran()

  },
  computed: {
    branches() {
      return this.$store.state.branches
    },
    role() {
      return localStorage.getItem("role");
    },
  },  
  methods: {
    resetFilter() {
      this.findBranchId= 0
      this.endDate= ""
      this.startDate= ""
      this.keyword= ""
      this.getDataTable()
    },
    async getDataTable() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/jurnal",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            startDate: this.startDate,
            endDate: this.endDate,
            page: this.currentPage,
            limit: this.perPage,
            jurnalKategori: this.findJurnalKategori,
            BranchId: this.findBranchId,
            keyword: this.keyword,
          }
        })
        this.dataTable = data.data2.result
        let totalPage = Math.ceil(data.data2.dataLength / this.perPage)
				this.totalPage = totalPage
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
    async getOneJurnal(id) {

      try {
        let {data} = await axios({
          method: "GET",
          url: `/jurnal/onejurnal/${id}`,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.jurnal = data
        // $('#mainModal').modal('show')
        this.$store.commit("setJurnal", data);
        this.$router.push("/editjurnal")
        // console.log(data, "juuuurrrnal");

      } catch(err) {
        console.log(err);
      }
    },
    async seeJurnal(id) {

      try {
        let {data} = await axios({
          method: "GET",
          url: `/jurnal/onejurnal/${id}`,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.jurnal = data
        $('#mainModal').modal('show')
        console.log(data,"---------");

        this.$store.commit("setJurnal", data);

      } catch(err) {
        console.log(err);
      }
    },
    async getMataAnggaran() {
      this.isLoading = true
      try {
        let kelMa = await axios({
          method: "GET",
          url: "/database/kelompokma",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })

        let ma = await axios({
          method: "GET",
          url: "/database/mataanggaran",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })

        let subMa = await axios({
          method: "GET",
          url: "/database/subma",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
      
        this.kelma = kelMa.data
        this.subma = subMa.data
        this.ma = ma.data

        this.isLoading = false

      } catch(err) {
        console.log(err);
      }
    },
    async getProject() {
      try {
        let project = await axios({
          method: "GET",
          url: "/user/project",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            BranchId : this.BranchId
          }
        })
        this.projects = project.data
        this.getDataTable()
      } catch(err) {
        console.log(err);
      }
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY")
    },
    cekStatus(data) {
      let result = ""
      if(data == 2) {
        result = 'By Planing'
      } else if(data == 1) {
        result= 'Urgent'
      }
      return result
    },
    formatUang(data) {
      // console.log(data);
			let uang = "";
			data = data.toString();
			for (let i = 0; i < data.length; i++) {
				if ((data.length - i) % 3 == 0 && i !== 0) {
				uang += `.${data[i]}`;
				} else {
				uang += data[i];
				}
			}
      return uang
    },

    async deleteData(id) {
      
      try {
        
        let data =await  axios.delete(`/jurnal/${id}`, {
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Berhasil', 'Data Berhasil Dihapuskan', 'success');
        this.getDataTable()

      } catch(err) {
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');

      } 
    },
    async confirmDelete(id) {
      
      try {
        let data = await Swal.fire({
            title: 'Yakin?',
            text: 'Apakah Yakin ',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Hapus!!',
        })
        if (data.isConfirmed) {
            this.deleteData(id);
            this.getDataTable()
        } else {
                Swal.fire('Ok', 'Proses anda berhasil dibatalkan', 'error');
              }
              
              
            } catch(err) {
              
              Swal.fire('Maaf', 'Proses anda berhasil dibatalkan', 'error');
      }
    },
    addPage() {
      this.currentPage = this.currentPage + 1
      this.getDataTable()
    },
    minPage() {
      this.currentPage = this.currentPage - 1
      this.getDataTable()
    },
  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }




</style>