<template>
  <div class="mainPage">
    <p id="headPage">Mutasi Product</p>
    
    <div id="titleRow">
      <b-button id="addModal" @click.prevent="showAdd()">Tambah Data</b-button>
      <button @click.prevent="printCsv()" id="printer">
          <img src="../assets/printer.png" id="printer" alt="">
      </button>
    </div>
    <div class="filterRow">
       
      <div class="inRow">
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Cabang</p>
          <b-select v-model="BranchId" @change="this.getDataTable">
            <option value="0" >Semua Cabang </option>
            <option v-for="e in this.branches" :key="e.id" :value="e.id"> {{e.branchName}} </option>
          </b-select>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle" >Tanggal Mulai</p>
          <b-input type="date"  v-model="startDate"  @change="this.getDataTable"></b-input>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Tanggal Selesai</p>
          <b-input type="date" v-model="endDate"  @change="this.getDataTable"></b-input>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Jenis Mutasi</p>
          <b-select v-model="type" @change="this.getDataTable">
            <option value="0" >Semua Jenis </option>
            <option value="1" >Masuk </option>
            <option value="2" >Keluar </option>
          </b-select>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Keyword</p>
          <b-input type="text" v-model="keyword"  @change="this.getDataTable"></b-input>
        </div>
      </div>
      <div class="inRow">


      </div>
    </div>
    <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p> 
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
      <!-- table -->
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>Tanggal</th>
            <th>Nomor Ref.</th>
            <th>Cabang</th>
            <th>Lokasi</th>
            <th>Nama Product</th>
            <th>Type Product</th>
            <th>Jenis Mutasi</th>
            <th>Keterangan</th>
            <th>Jumlah</th>
            <th>Nilai</th>
            <!-- <th colspan="2">Aksi</th> -->
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in this.dataTable" :key="e.id">
            <!-- <td>{{ e }}</td> -->
            <td>{{ formatDate(e.date) }}</td>
            <td> {{ e.refNo || '-' }}</td>
            <td>{{ e.Branch.branchName || '-' }}</td>
            <td>{{ e.Project.projectName || '-' }}</td>
            <td> {{e.Produck.produckName}} </td>
            <td> {{e.Produck.type}} </td>
            <td> {{ e.type == 1 ? 'Penambahan' : 'Pengurangan'}} </td>
            <td>{{ e.notes }}</td>
            <td class="text-right">{{ e.volume }}</td>
            <td class="text-right">{{ formatUang(e.amount) }}</td>
            <!-- <td>{{ e.Produck.produckName }}</td> -->
            <td id="action" class="text-center" >
                <button @click.prevent="setPrintData(e)" title="Print" >
                  <img src="../assets/print2.png" alt="">
                </button>
                <button @click.prevent="confirmDelete(e.id)" title="Hapus">
                    <lord-icon
                        src="https://cdn.lordicon.com/gsqxdxog.json"
                        trigger="hover">
                    </lord-icon>
                </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    </div>

    <!-- modal -->
  <div class="modal" id="mainModal" tabindex="-1">
  <!-- <div class="modal-dialog"> -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Mutasi Product</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" >&times; </span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="isEdit ? editData() : addData()">

        <div class="inRow">
          <div class="inCol">
            <p id="titleModal">Cabang</p>
            <b-select v-model="add.BranchId" required @change="this.getProject">
              <option value="0" disabled>Pilih Cabang </option>
              <option v-for="e in this.branches" :key="e.id" :value="e.id"> {{e.branchName}} </option>
            </b-select>
          </div>
          <div class="inCol">
            <p id="titleModal">Project</p>
            <b-select v-model="add.ProjectId" required>
              <option v-for="e in this.projects" :key="e.id" :value="e.id"> {{e.projectName}} </option>
            </b-select>
          </div>
          <div class="inCol">
            <p id="titleModal">Product</p>
            <b-select v-model="add.ProduckId" required>
              <option v-for="e in this.products" :key="e.id" :value="e.id"> {{e.produckName}} - {{ e.type }} </option>
            </b-select>
          </div>
          <div class="inCol">
            <p id="titleModal">Tanggal</p>
            <b-input type="date" v-model="add.date" required></b-input>
          </div>
        </div>
        <div class="inRow">
          <div class="inCol">
            <p id="titleModal">Jenis Mutasi</p>
            <b-select v-model="add.type" required>
              <option :value="1"> Masuk </option>
              <option :value="2"> Keluar </option>
            </b-select>
          </div>
          <div class="inCol">
            <p id="titleModal">Jumlah</p>
            <b-input type="number" class="text-right"  v-model="add.volume" required> </b-input>
          </div>
          <div class="inCol">
            <p id="titleModal">Keterangan</p>
            <b-input type="text"  v-model="add.notes" placeholder="Masukkan Catatan" required ></b-input>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary"> {{ isEdit ? 'Edit' : 'Tambah' }} </button>
        </div>
      </form>
      </div>
    </div>
  <!-- </div> -->
</div>

<!-- Print -->
<div id="printArea">
      <div class="headPrint">
        <p id="titlePrint" v-if="print.type == 1">Product Masuk</p>
        <p id="titlePrint" v-if="print.type == 2">Product Keluar</p>
        <p id="brancName">{{ print.branchName }}</p>
        <p id="datePrint">{{ print.date }}</p>
      </div>
      <hr />
      <div class="mainPrint">
        <table class="table table-borderless" id="printTable">
          <tr>
            <td id="keyTable">Cabang</td>
            <td id="titikDua">:</td>
            <td>{{ print.project }}</td>
          </tr>
          <tr>
            <td id="keyTable">Jumlah</td>
            <td id="titikDua">:</td>
            <td>{{ print.volume }}</td>
          </tr>
          <tr>
            <td id="keyTable">Product</td>
            <td id="titikDua">:</td>
            <td>{{ print.produckName }}</td>
          </tr>
          <tr>
            <td id="keyTable">Total Harga</td>
            <td id="titikDua">:</td>
            <td>{{ formatUang(print.amount) }} </td>
          </tr>
          <tr>
            <td id="keyTable">Keteerangan</td>
            <td id="titikDua">:</td>
            <td>{{ print.notes }} </td>
          </tr>
        </table>
        <hr />
      </div>
      <div class="signArea">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Nama Material</th>
              <th>Jumlah</th>
              <th>Harga</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="e in this.print.MaterialMutasions" :key="e.id"> 
              <td>{{ e.Material.materialName }}</td>
              <td class="text-right">{{ formatUang(e.volume) }}</td>
              <td class="text-right">{{ formatUang(e.amount) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="signArea">
        <div class="inRow">
          <div class="inCol">
            <p id="name">Gudang</p>
            <p>(............................)</p>
          </div>
          <div class="inCol">
            <p id="name">Produksi</p>
            <p>(............................)</p>
          </div>
          <div class="inCol">
            <p id="name">Akuntansi</p>
            <p>(............................)</p>
          </div>
        </div>
      </div>
      <hr />
    </div>

  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment'
import html2pdf from "html2pdf.js";
import csvDownload from 'json-to-csv-export'
export default {
  name: "Voucer",
  data() {
    return  {
      BranchId: 0,
      ProduckId: 0,
      dataTable: [],
      id: 0,
      type: 0,
      currentPage: 1,
      perPage: 20,
			totalPage : 2,
      isLoading: false,
      isEdit: false,
      keyword: '',
      // fill modal
      products: [],
      projects: [],
      // filter
      startDate: '',
      endDate: '',
      add: {
        ProjectId: 0,
        ProduckId: 0,
        date: '',
        BranchId: 0,
        type: 1,
        volume: 0,
        notes: '',
      },
      print: {
        date: '',
        produckName: '',
        project: '',
        branchName: '',
        MaterialMutasions: '',
        amount: 0,
        volume: 0,
        type: 0,
        notes: ''
      }
    }
  },
  created() {
    this.getDataTable()

    this.getProduct()
    this.$store.dispatch("getBranch")
  },
  computed: {
    branches() {
      return this.$store.state.branches
    }
  },  
  methods: {

    showAdd() {
      this.isEdit = false
      this.produckName = ""
      this.type = ""
      $('#mainModal').modal('show')
    },

    showEdit(e) {
      console.log(e);
      this.produckName = e.produckName
      this.type = e.type
      
      this.subItems = e.StandartProducks
      this.id = e.id
      this.isEdit = true
      $('#mainModal').modal('show')
    },

    addRow() {
      this.subItems.push({
        id: uuidv4(),
        MaterialId: 0,
        volume: 0
      })
    },

    async getDataTable() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/product/mutation",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            BranchId: this.BranchId,
            startDate: this.startDate,
            type: this.type,
            endDate: this.endDate,
            keyword: this.keyword,
            ProduckId: this.ProduckId,
            page: this.currentPage,
            limit: this.perPage,
          }
        })
        this.dataTable = data.data2.result
        let totalPage = Math.ceil(data.data2.dataLength / this.perPage)
				this.totalPage = totalPage
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
    async printCsv() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/product/mutation/print",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            BranchId: this.BranchId,
            startDate: this.startDate,
            type: this.type,
            endDate: this.endDate,
            keyword: this.keyword,
            ProduckId: this.ProduckId,
            page: this.currentPage,
            limit: this.perPage,
          }
        })
        console.log(data);
        const dataToConvert = {
          data: data,
          filename: 'Product',
          delimiter: ',',
        }
        csvDownload(dataToConvert)
        this.isLoading= false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
    formatUang(data) {
      // console.log(data);
			let uang = 0;
      if(data) {
        uang = ""
        data = data.toString();
        for (let i = 0; i < data.length; i++) {
          if ((data.length - i) % 3 == 0 && i !== 0) {
          uang += `.${data[i]}`;
          } else {
          uang += data[i];
          }
        }
      }
      return uang
    },
    async getProject() {

      try {
        let {data} = await axios({
          url: "/user/project",
          params: {
           BranchId:  this.add.BranchId
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.projects = data
        this.getVendor()
      } catch(err) {
        console.log(err);
      }
    },

    async getProduct() {
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/product",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        console.log(data);
        this.products = data

      } catch(err) {

        console.log(err);
      }
    },

    async addData() {


      try {
        let payload = {
          BranchId : this.add.BranchId,
          ProjectId : this.add.ProjectId,
          ProduckId : this.add.ProduckId,
          date : this.add.date,
          type : this.add.type,
          volume : this.add.volume,
          notes : this.add.notes,
        }  
        let proses = await axios({
          method: "POST",
          url: "/product/mutation",
          data: payload,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.materialName = "",
        this.getDataTable()
        $('#mainModal').modal('hide')
        Swal.fire('Berhasil', 'Berhasil Diproses', 'success');
      } catch(err) {
        console.log(err);
        this.isLoading = false
        Swal.fire('Maaf', 'Periksa Kembali Data anda', 'error');
      }
    },
    async editData(e) {

      try {

        if(this.subItems.length < 1) {
          throw {
            status: 500,
            msg: 'No'
          }
        }

        this.subItems.map(e => {
          if(e.MaterialId == 0 || e.volume == 0) {
            throw {
            status: 500,
            msg: 'Data is not complete'
          }
          }
        })

        let payload = {
            items: this.subItems,
            ProduckId: this.id,
            produckName : this.produckName,
            type : this.type,
        }
        let data3 = await axios({
          method: "post",
          url: `/product/standar`,
          data: payload,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        let data2 = await axios({
          method: "put",
          url: `/product/${this.id}`,
          data: payload,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })



        $('#mainModal').modal('hide')
        this.produckName=  ""
        this.type=  ""
        this.id=  0
        this.isEdit = false
        this.getDataTable()
        this.$store.dispatch("getBranch")

        Swal.fire('Berhasil', 'Data Berhasil Diubah', 'success');
        
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    }, 

    async deleteData(id) {
      
      try {
        
        let data = await axios.delete(`/product/mutation/${id}`, {
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Berhasil', 'Data Berhasil Dihapuskan', 'success');
        this.$store.dispatch("getBranch")
        this.getDataTable()
      } catch(err) {
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');

      } 
    },
    async confirmDelete(id) {
      
      try {
        let data = await Swal.fire({
            title: 'Yakin?',
            text: 'Apakah Yakin ',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Hapus!!',
        })
        if (data.isConfirmed) { 
            await this.deleteData(id);
            this.$store.dispatch("getBranch")
            this.getDataTable()
        } else {
                Swal.fire('Ok', 'Proses anda berhasil dibatalkan', 'error');
              }
              
              
            } catch(err) {
              
              Swal.fire('Maaf', 'Proses anda berhasil dibatalkan', 'error');
      }
    },

    formatDate(date) {
      return moment(date).format("DD-MM-YYYY")
    },
    
    setPrintData(e) {
      (this.print.branchName = e.Branch.branchName),
      (this.print.date = moment(new Date()).format("DD-MM-YYYY"));
      this.print.notes = e.notes;
      this.print.amount = e.amount;
      this.print.volume = e.volume;
      this.print.type = e.type;
      this.print.produckName = `${e.Produck.produckName} - ${e.Produck.type}`;
      this.print.project = e.Project.projectName;

      this.print.MaterialMutasions = e.MaterialMutasions;

      this.exportToPDF();
    },
    exportToPDF() {
      this.isLoading = true;
      document.getElementById("printArea").style.display = "block";
      html2pdf(document.getElementById("printArea"), {
        margin: 1,
        filename: "Voucer.pdf",
      }).then((e) => {
        document.getElementById("printArea").style.display = "none";
        this.isLoading = false;
      });
    },

    addPage() {
      this.currentPage = this.currentPage + 1
          this.getDataTable()
    },
    minPage() {
      this.currentPage = this.currentPage - 1
          this.getDataTable()
    },
  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }



 




</style>