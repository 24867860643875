<template>
  <div class="mainPage">
    <div class="inRow" :style="{justifyContent: 'left'}">
      <p id="headPage">Kasir</p>
    </div>
    <div class="cardRow">
       
      <div class="inRow">
        <div class="card" id="startBalance">
          <p id="titleCard">Saldo Awal</p>
          <p id="amountCard">{{formatUang(totalBalance.startBalance)}}</p>
        </div>
        <div class="card" id="inCard">
          <p id="titleCard">Kas Masuk</p>
          <p id="amountCard">{{formatUang(totalBalance.cashIn)}}</p>
        </div>
        <div class="card" id="outCard">
          <p id="titleCard">Kas Keluar</p>
          <p id="amountCard">{{formatUang(totalBalance.cashOut)}}</p>
        </div>
        <div class="card" id="balanceCard">
          <p id="titleCard">Saldo Akhir</p>
          <p id="amountCard">{{formatUang(totalBalance.endBalance)}}</p>
        </div>
      </div>
    </div>

    <div class="filterRow">
       
      <div class="inRow">
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Tanggal Mulai</p>
          <b-input type="date" v-model="filter.startDate" @change="this.getDataTable"></b-input>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Tanggal Selesai</p>
          <b-input type="date" v-model="filter.endDate" @change="this.getDataTable"></b-input>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Jenis Transaksi</p>
          <b-select v-model="filter.type" @change="this.getDataTable">
            <option value="1">Masuk</option>
            <option value="2">Keluar</option>
          </b-select>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Keyword</p>
          <b-input v-model="filter.keyword" @change="this.getDataTable"></b-input>
        </div>
      </div>
    </div>
    <div id="titleRow">
      <b-button id="addModal" @click.prevent="showAdd()" v-if="role == 'owner'">Tambah Data</b-button>
      <b-button id="resetFilter" @click.prevent="resetFilter()">Reset Filter</b-button>
      <!-- <button @click.prevent="printCsv()" id="printer">
        <img src="../assets/printer.png" id="printer" alt="">
      </button> -->
    </div>

    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>Nomor Ref</th>
            <th>Tanggal</th>
            <th>Keterangan</th>
            <th>Jumlah</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
            <tr v-for="e in dataTable" :key="e.id">
              <td>{{ e.refNo }}</td>
              <td>{{ formatDate(e.date) }}</td>
              <td> {{e.detail}} </td>
              <td class="text-right">{{ formatUang(e.amount) }}</td>
              <td id="action" class="text-center" >
                <!-- <button @click.prevent="setPrintData(e)" title="Print" >
                  <img src="../assets/print2.png" alt="">
                </button> -->
                <button @click.prevent="confirmDelete(e)" title="Print" >
                  <img src="../assets/trash.png" alt="">
                </button>
              </td>
            </tr>
          
        </tbody>
      </table>
      <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>

    </div>
    
    <div class="modal" id="mainModal" tabindex="-1">
      <!-- <div class="modal-dialog"> -->
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Transaksi Pertashop</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times; </span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="isEdit ? editData() : addData()">

            <div class="inRow">
              <div class="inCol">
                <p id="inputTitle">Tanggal</p>
                <b-input type="date" v-model="add.date"></b-input>
              </div>
              <div class="inCol">
                <p id="inputTitle">Jenis Transaksi</p>
                <b-select v-model="add.type">
                  <option value="1">Masuk</option>
                  <option value="2">Keluar</option>
                </b-select>
              </div>
              <div class="inCol">
                <p id="inputTitle">Jumlah</p>
                <b-input class="text-right" type="text" v-model="add.amount" @input="currencyNumber($event)"  required></b-input>
              </div>
              <div class="inCol">
                <p id="inputTitle">Keterangan</p>
                <b-input type="text" v-model="add.detail" required></b-input>
              </div>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="submit" class="btn btn-primary">Tambah</button>
            </div>
          </form>
        </div>
      </div>
      <!-- </div> -->
    </div>

    <!-- Modal Status -->

    <!-- Print -->

  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import moment from 'moment'
import csvDownload from 'json-to-csv-export'

export default {
  name: "Voucer",
  data() {
    return  {
      id: 0,
      isEdit: false,
      isLoading: false,
      currentPage: 1,
      perPage: 40,
			totalPage : 1,
      keyword : '',
      dataTable: [],
      status: 0,
      total: 0,

      add : {
        detail: '',
        type: '',
        date: '',
        amount: 0
      },

      totalBalance: {
        startBalance: 0,
        cashIn: 0,
        cashOut: 0,
        endBalance: 0,
      },
      filter: {
        keyword: '',
        startDate: '',
        endDate: '',
      },

      print: {
        refNo: '',
        branchName: '',
        approveDate: '',
        payDate: '',
        detail: '',
        amount: '',
        date: '',
        inputDate: ''
      }

    
    }
  },
  created() {
    this.$store.dispatch("getBranch")
    this.getDataTable()
    
  },
  computed: {
    branches() {
      return this.$store.state.branches
    },
    role() {
      return localStorage.getItem('role')
    }
  },  
  methods: {
    
    showAdd() {
      this.isEdit = false
      $('#mainModal').modal('show')
    },
    resetFilter() {
      this.filter = {
        keyword: '',
        startDate: '',
        endDate: '',
      },
      this.getDataTable()
      
    },
    
    setPrintData(e) {
      if(!e.isApprove || !e.isPay) {
        return Swal.fire('Maaf', 'Data ini belum disetujui / dibayar', 'error');
      }

      this.print.branchName = e.Branch.branchName,
      this.print.date = moment(new Date()).format('DD-MM-YYYY')
      this.print.inputDate = moment(e.date).format('DD-MM-YYYY')
      this.print.nomor = e.refNo
      this.print.amount = this.formatUang(e.amount)
      this.print.detail = e.detail
      this.exportToPDF()
    },

    exportToPDF() {
      this.isLoading = true
      document.getElementById('printArea').style.display='block'
      html2pdf(document.getElementById('printArea'),  {
        margin: 1,
        filename: "Voucer.pdf",
      }).then(e => {
        document.getElementById('printArea').style.display='none'
        this.isLoading = false
      })
    },
    showEdit(e) {
      this.isEdit = true
      this.id = e.id
      this.add.departingFrom = e.departingFrom
      this.add.destination = e.destination
      this.add.truckSize = e.truckSize
      this.add.truckType = e.truckType
      this.add.amount = this.setEditCurrent(e.amount)
      $('#mainModal').modal('show')
    },
    
    async getDataTable() {
      // get kendaraan
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/pertashop",
          params: {
            page: this.currentPage,
            limit: this.perPage,
            keyword: this.filter.keyword,
            startDate: this.filter.startDate,
            endDate: this.filter.endDate,
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.totalBalance = data.totalBalance,
        this.dataTable = data.data2.result
        let totalPage = Math.ceil(data.data2.dataLength / this.perPage)
        this.totalPage = totalPage
        this.isLoading = false
        console.log(data," ================");
              
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }
    },

    currencyNumber(event, target) {
        console.log(event, "===================");
        let currentValue = event.trim().split('.').filter(e => e != '.' ).join('')

        console.log(currentValue, typeof currentValue," -----------");
        if( Number.isNaN(Number(currentValue))) {
          // this.amount = 10
          this.add.amount = this.formatUang(currentValue.split('').filter(e => !Number.isNaN( Number(e))).join(''))
          return
        }
        // currentValue = this.amount
        this.add.amount = this.formatUang(currentValue)
        // currentValue = this.amount
        // this.amount = this.amount.toLocaleString('id-ID')
        console.log(this.amount, "++++++++++++++");
    },

    setEditCurrent(currentValue) {
      let result = 0
      if( Number.isNaN(Number(currentValue))) {
          this.amount = this.formatUang(currentValue.split('').filter(e => !Number.isNaN( Number(e))).join(''))
          return
      }
      result = this.formatUang(currentValue)
      return result  
    },

    async addData() {
      this.add.amount = this.add.amount.trim().split('.').filter(e => e != '.' ).join('')
      try {
        let data = await axios({
          url: '/pertashop',
          method: 'post',
          data: this.add,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Berhasil', 'Data Berhasil Ditambahkan', 'success');
        this.getDataTable()
        $('#mainModal').modal('hide')
      } catch (err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    },

    async editData() {
      this.add.amount = this.add.amount.trim().split('.').filter(e => e != '.' ).join('')
      try {
        let data = await axios({
          url: `/retase/fare/${this.id}`,
          method: 'put',
          data: this.add,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Berhasil', 'Data Berhasil Diproses', 'success');
        this.getDataTable()
        $('#mainModal').modal('hide')
      } catch (err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    },

    async confirmDelete(e) {
      
      try {
        if(e.isPay || e.isApprove) {
          Swal.fire('Maaf', 'Data tidak dapat dihapus', 'error');
        } else {
          this.deleteData(e)
        }
        
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Proses Tidak Dapat dijalankan', 'error');
      }
    },

    async deleteData(e) {
      
      try {
        if(e.isPay || e.isApprove) {
          Swal.fire('Maaf', 'Data tidak dapat dihapus', 'error');
        }
        let penm = await Swal.fire({
          title: 'Apakah anda yakin',
          text: "Hapus data?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes!'
        }).then( async (result) => {
          if (result.isConfirmed) {

            let data = await axios({
              method: 'delete',
              url: `/pertashop/${e.id}`,
              headers: {
                access_token: localStorage.getItem("access_token")
              },
            })
            this.getDataTable()
            Swal.fire('Berhasil', 'Data Berhasil Diproses', 'success');
          } else {
            Swal.fire('Ok', 'Berhasil Dibatalkan', 'error');
          }
        })
            
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Proses Tidak Dapat dijalankan', 'error');
      }
    },

    async printCsv() {
      alert("yes")
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/payment/print",
          params: {
            keyword: this.keyword
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        const dataToConvert = {
          data: data,
          filename: 'Peyment',
          delimiter: ',',
        }
        csvDownload(dataToConvert)
        this.isLoading= false
        alert("yes2")
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },

    formatUang(data) {
      // console.log(data);
			let uang = 0;
      if(data) {
        uang = ""
        data = data.toString();
        for (let i = 0; i < data.length; i++) {
          if ((data.length - i) % 3 == 0 && i !== 0) {
          uang += `.${data[i]}`;
          } else {
          uang += data[i];
          }
        }
      }
      return uang
    },

    formatDate(date) {
      let result = ''
      if(date) result = moment(date).format("DD-MM-YYYY")
      return result
    },

  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }




</style>