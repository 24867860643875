<template>
  <div class="mainPage">
    <p id="headPage">Rent Order</p>

    <div class="cardRow">
      <div class="inRow">
        <div class="card" id="balanceCard">
          <p id="titleCard">Total Pendapatan</p>
          <p id="amountCard">{{formatUang(totalPrice)}}</p>
        </div>
      </div>
    </div>
    <div id="titleRow">
      <b-button id="addModal" @click.prevent="showAdd()" v-if="role == 'owner' || role == 'material'  || role == 'accountant'">Tambah Data</b-button>
      <button @click.prevent="printCsv()" id="printer">
          <img src="../assets/printer.png" id="printer" alt="">
      </button>
    </div>
    <div class="filterRow">
      <!--   -->
      <div class="inRow">
        <div class="inCol" id="cardFilter">
          
          <p id="inputTitle" >Tanggal Mulai</p>
          <b-input type="date"  v-model="startDate"  @change="this.getDataTable"></b-input>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Tanggal Selesai</p>
          <b-input type="date" v-model="endDate"  @change="this.getDataTable"></b-input>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Jenis</p>
          <b-select v-model="RentTypeId" @change="this.getDataTable">
            <option v-for="e in types" :key="e.id" :value="e.id"> {{ e.type }}</option>
          </b-select>
        </div>
        <div class="inCol"  id="cardFilter">
          <p id="inputTitle">Keyword</p>
          <b-input v-model="keyword"  @change="this.getDataTable"></b-input>
        </div>
        
      </div>
    </div>
    <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p> 
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>Tanggal</th>
            <th>Type Kendaraan</th>
            <th>Tujuan - Pembeli</th>
            <th>Total Volume</th>
            <th v-if="role == 'owner'">Total Harga</th>
            <th>Keterangan</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in this.dataTable" :key="e.id">
            <!-- <td>{{ e.Apd.name}} -{{ e.Apd.type }} - {{ e.Apd.size }} - {{ e.Apd.color }} -{{ e.Apd.detail }}</td> -->
            <!-- <td class="text-right">{{ e }}</td> -->
            <td class="text-left">{{ formatDate(e.date) }}</td>
            <td class="text-left">{{ e.RentType?.type }} - {{ e.RentType?.rate}}</td>
            <td class="text-left">{{ e.destination }} - {{ e.buyer }}</td>
            <td class="text-right">{{ formatUang(e.volume) }}</td>
            <td v-if="role == 'owner'" class="text-right">{{ formatUang(e.totalPrice) }}</td>
            <td class="text-left">{{ e.notes }}</td>
            <td id="action" class="text-center">
              <button v-if="e.status" @click.prevent="editStatus(e.id)">
                <img src="../assets/check.png" >
              </button>
              <img src="../assets/wrong.png" v-if="!e.status" >
            </td>
            <td id="action" class="text-center" >
              <button @click.prevent="confirmDelete(e.id)" title="Hapus">
                <img src="../assets/trash.png" alt="">
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    </div>

    <!-- modal -->
    <div class="modal" id="mainModal" tabindex="-1">
  <!-- <div class="modal-dialog"> -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Mutasi Rent</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" >&times; </span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="isEdit ? editData() : addData()">

          <div class="inRow">
            <div class="inCol">
              <p id="titleModal">Tanggal</p>
              <b-input v-model="add.date" required type="date"></b-input>
            </div>
            <div class="inCol">
              <p id="titleModal">Jenis</p>
              <b-select v-model="add.RentTypeId" required>
                <option v-for="e in types" :key="e.id" :value="e.id"> {{ e.type }} - {{ e.rate }}</option>
              </b-select>
            </div>
            <div class="inCol">
              <p id="titleModal">Volume</p>
              <b-input class="text-right" v-model="add.volume" type="number"   @input="currencyNumber($event)" required></b-input>
            </div>
          </div>
          <div class="inRow">
            <div class="inCol">
              <p id="titleModal">Tujuan</p>
              <b-input v-model="add.destination" required type="text" ></b-input>
            </div>
            <div class="inCol">
              <p id="titleModal">Pemesan</p>
              <b-input v-model="add.buyer" required type="text"></b-input>
            </div>
          </div>
          <div class="inRow">
            <div class="inCol">
              <p id="titleModal">Keterangan</p>
              <b-input v-model="add.notes" required type="text"></b-input>
            </div>
          </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary"> {{ isEdit ? 'Edit' : 'Tambah' }} </button>
        </div>
      </form>
      </div>
    </div>
  <!-- </div> -->
  </div>


  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import moment from 'moment'
import csvDownload from 'json-to-csv-export'
export default {
  name: "Voucer",
  data() {
    return  {
      id: 0,
      dataTable: [],
      types: [],
      currentPage: 1,
      perPage: 40,
			totalPage : 1,
      isLoading: false,
      isEdit: false,
      keyword: '',
      totalPrice: 0,
      RentTypeId: '',
      totalRetase: 0,
      startDate:"",
      endDate:"",
      add: {
        date: "",
        RentTypeId: '',
        plat: '',
        destination: '',
        driver: '',
        retase: '',
        volume: '',
        notes: '',
        buyer: '',
        amount: 0 ,

      }

    }
  },
  created() {
    this.$store.dispatch("getBranch")
    this.getDataTable()
    this.getType()

  },
  computed: {
    branches() {
      return this.$store.state.branches
    },
    role() {
      return localStorage.getItem("role");
    },
  },  
  methods: {
    showAdd() {
      this.add.date = moment(new Date()).format("YYYY-MM-DD")
      this.isEdit = false
      $('#mainModal').modal('show')
    },
    async getDataTable() {

      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/rent/order",
          params: {
            page: this.currentPage,
            limit: this.perPage,
            keyword: this.keyword,
            RentTypeId: this.RentTypeId,
            startDate: this.startDate,
            endDate: this.endDate,
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.dataTable = data.data2.result
        this.totalBalance = data.totalBalance
        let totalPage = Math.ceil(data.data2.dataLength / this.perPage)
				this.totalPage = totalPage
        this.totalPrice = data.totalPrice
        this.totalRetase = data.retase
        this.isLoading = false
        console.log(data);
      } catch(err) {
        console.log(err);
        this.isLoading = false
      }
    },
    formatUang(data) {
      // console.log(data);
			let uang = 0;
      if(data) {
        uang = ""
        data = data.toString();
        for (let i = 0; i < data.length; i++) {
          if ((data.length - i) % 3 == 0 && i !== 0) {
          uang += `.${data[i]}`;
          } else {
          uang += data[i];
          }
        }
      }
      return uang
    },
    async getType() {
      this.isLoading = true
      try {
        let {data} = await axios({
          method: "GET",
          url: "/rent/type",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        console.log(data, "dataaaa");
        this.types = data
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }
    },
    async addData() {

      try {

        let proses = await axios({
          method: "POST",
          url: "/rent/order",
          data: this.add,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.getDataTable()
        $('#mainModal').modal('hide')
        Swal.fire('Berhasil', 'Telah diproses', 'success');
      } catch(err) {
        console.log(err.response.data);
        this.isLoading = false
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    },
    async editStatus(id) {

      try {

        let proses = await axios({
          method: "put",
          url: `/rent/order/status/${id}`,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.getDataTable()
        Swal.fire('Berhasil', 'Telah diproses', 'success');
      } catch(err) {
        console.log(err.response.data);
        this.isLoading = false
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    },
    async deleteData(id) {
      
      try {
        
        let data = await axios.delete(`/rent/order/${id}`, {
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Berhasil', 'Data Berhasil Dihapuskan', 'success');
        this.getDataTable()

      } catch(err) {
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');

      } 
    },
    async confirmDelete(id) {
      
      try {
        let data = await Swal.fire({
            title: 'Yakin?',
            text: 'Apakah Yakin ',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Hapus!!',
        })
        if (data.isConfirmed) {
            this.deleteData(id);
            this.getDataTable()
        } else {
                Swal.fire('Ok', 'Proses anda berhasil dibatalkan', 'error');
              }
              
            } catch(err) {
              
              Swal.fire('Maaf', 'Proses anda berhasil dibatalkan', 'error');
      }
    },
    async printCsv() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/rent/print",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            keyword: this.keyword,
            startDate: this.startDate,
            RentTypeId: this.RentTypeId,
            endDate: this.endDate,
          }
        })
        const dataToConvert = {
          data: data,
          filename: `Rent`,
          delimiter: ',',
        }
        csvDownload(dataToConvert)
        this.isLoading= false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
    async printExcel() {
  this.isLoading = true;

  try {
    // return Swal.fire('Berhasil', 'Telah diproses', 'success');
    const { data } = await axios({
      method: "GET",
      url: "/rent/print",
      headers: {
        access_token: localStorage.getItem("access_token")
      },
      params: {
        keyword: this.keyword,
        startDate: this.startDate,
        endDate: this.endDate
      },
      responseType: 'blob' // Tentukan tipe respon 'blob' untuk download Excel
    });

    // Gunakan FileSaver.js untuk pembuatan file Excel yang efisien dan lintas-browser
    const ExcelJS = await import(/* webpackChunkName: "exceljs" */ 'exceljs'); // Impor dinamis untuk bundling optimal

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data Rent'); // Sesuaikan nama sheet sesuai keinginan

    // Proses data untuk Excel (ganti dengan logika pemformatan data Anda)
    const headers = ['Kolom 1', 'Kolom 2', /* ... */]; // Ganti dengan nama kolom sebenarnya
    worksheet.addRow(headers);
    data.result.forEach(row => worksheet.addRow(row)); // Asumsikan data adalah array of array

    const filename = `Rent-${new Date().toISOString()}.xlsx`; // Sertakan timestamp untuk keunikan

    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const fileURL = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = fileURL;
      link.setAttribute('download', filename);
      link.click();
    });

    this.isLoading = false;
  } catch (err) {
    this.isLoading = false;
    console.error(err);
  }
},
    formatDate(date) {
      let result = ''
      if(date) return moment(date).format("DD-MM-YYYY")
    },

    addPage() {
      this.currentPage = this.currentPage + 1
          this.getDataTable()
    },
    minPage() {
      this.currentPage = this.currentPage - 1
          this.getDataTable()
    },
  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }




</style>