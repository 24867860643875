<template>
  <div class="mainPage">
  --{{ jurnal }}--

    <p id="headPage">Jurnal</p>
    <div class="filterRow">
       
      <form action="">

        <div class="inRow">
          <div class="inCol" id="cardFilter">
            <p id="inputTitle">Tanggal</p>
            <b-input type="date" v-model="date"></b-input>
          </div>
          <div class="inCol" id="cardFilter">
            <p id="inputTitle">Cabang</p>
            <b-select v-model="BranchId" @change="this.getProject">
              <option value="0" disabled>Pilih Cabang </option>
              <option v-for="e in this.branches" :key="e.id" :value="e.id"> {{e.branchName}} </option>
            </b-select>
          </div>
          <div class="inCol" id="cardFilter">
            <p id="inputTitle">Keterangan</p>
            <b-input type="text" v-model="notes" id=""></b-input>
          </div>
        </div>
        <p id="headPage">Sub Jurnal</p>
        <div class="tableRow">
          <div class="inRow">
            <lord-icon
              v-if="this.isLoading"
              src="https://cdn.lordicon.com/dpinvufc.json"
              trigger="loop"
              style="width:300px;height:300px">
            </lord-icon>
          </div>
        </div>
        <div id="titleRow">
          <b-button id="addModal" @click.prevent="addRow()">Tambah Baris</b-button>
        </div>

        <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
          <thead>
            <tr>
              <th>Kelompok Mata Anggaran</th>
              <th>Mata Anggaran</th>
              <th>Sub Mata Anggaran</th>
              <th>Project</th>
              <th>Posisi</th>
              <th>Jumlah</th>
              <th>Aksi</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="e in subJurnals" :key="e.id">
              <td>
                <b-select v-model="e.KelompokMaId" required>
                  <option :value="x.id" v-for="x in kelMa" :key="x.id">{{x.kelompokName}}</option>
                </b-select>
              </td>
              <td>
                <b-select v-model="e.MataAnggaranId" required>
                  <option :value="x.id" v-for="x in getMa(e.KelompokMaId)" :key="x.id">{{x.mataAnggaranName}}</option>
                </b-select>
              </td>
              <td>
                <b-select v-model="e.SubMataAnggaranId" required>
                  <option :value="x.id" v-for="x in getSubMa(e.MataAnggaranId)" :key="x.id">{{x.subMataAnggaranName}}</option>
                </b-select>
              </td>
              <td>
                <b-select v-model="e.ProjectId" required>
                  <option :value="x.id" v-for="x in projects" :key="x.id">{{x.projectName}}</option>
                </b-select>
              </td>
              <td>
                <b-select required v-model="e.NormalBalanceId">
                  <option value="11">Debet</option>
                  <option value="12">Kredit</option>
                </b-select>
              </td>
              <td>
                <b-input v-model="e.amount" type="number" required></b-input>
              </td>
              <td>
                <button @click.prevent="deleteRow(e.id)">
                  <img src="../assets/trash.png" alt="">
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <b-button  @click.prevent="addData()">Submit</b-button>
      </form>
    </div>
  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import moment from 'moment'
import csvDownload from 'json-to-csv-export'
import { v4 as uuidv4 } from 'uuid';
export default {
  name: "Voucer",
  data() {
    return  {
      isLoading : false,
      id: 0,
      projects: [],
      types: [],
      isLoading: false,
      isEdit: false,

      subJurnals: [{
        id: 1,
        KelompokMaId: 0,
        MataAnggaranId: 0,
        SubMataAnggaranId: 0,
        NormalBalanceId : 0,
        ProjectId: 0,
        amount: 0
      }],

      kelMa: [],
      ma: [],
      subMa: [],
      
      
      // filter
    
      // modal
      BranchId: 0,
      date:"",
      notes: "",
    }
  },
  created() {
    this.$store.dispatch("getBranch")
    this.getMataAnggaran()
    this.getProject()
  },
  computed: {
    branches() {
      return this.$store.state.branches
    },
    jurnal() {
      return this.$store.state.jurnal
    }
  }, 
  methods: {
    async getProject() {
      try {
        let project = await axios({
          method: "GET",
          url: "/user/project",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            BranchId : this.BranchId
          }
        })
        this.projects = project.data
      } catch(err) {
        console.log(err);
      }
    },
    addRow(){
      this.subJurnals.push(
        {
        id: uuidv4(),
        KelompokMaId: 0,
        MataAnggaranId: 0,
        SubMataAnggaranId: 0,
        NormalBalanceId : 0,
        amount: 0
      }
      )
    },
    deleteRow(id) {
      this.subJurnals = this.subJurnals.filter(e => e.id !== id)
    },  
    async addData() {
      let data = {
        subJurnals : this.subJurnals,
        BranchId : this.BranchId,
        date : this.date,
        notes : this.notes,
      }

      console.log(this.subJurnals,"=====");
      try {

        let proses = await axios({
          method: "POST",
          url:"/jurnal",
          data: data,
          headers: {
            access_token: localStorage.getItem("access_token")
          },

        })
        alert("Berhasil")
      } catch(err) {
        console.log(err);
        alert("Gaggaaaal")

      }
    },
    async getMataAnggaran() {
      this.isLoading = true
      try {
        let kelMa = await axios({
          method: "GET",
          url: "/database/kelompokma",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })

        let ma = await axios({
          method: "GET",
          url: "/database/mataanggaran",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })

        let subMa = await axios({
          method: "GET",
          url: "/database/subma",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
      
        this.kelMa = kelMa.data
        this.subMa = subMa.data
        this.ma = ma.data

        this.isLoading = false

      } catch(err) {
        console.log(err);
      }
    },
    getMa(KelompokMaId) {
      // return (this.ma.find(e => KelompokMaId == e.KelompokMaId))
      return (this.ma.filter(el => el.KelompokMaId == KelompokMaId) || [])
    },
    getSubMa(mataANggaranId) {
      // return (this.ma.find(e => mataANggaranId == e.mataANggaranId))
      return (this.subMa.filter(el => el.MataAnggaranId == mataANggaranId) || [])
    },
    


    


  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }
  button img{
    max-width: 24px;
  }




</style>