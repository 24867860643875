<template>
  <div class="mainPage">
    <p id="headPage">PENAGIHAN</p>
    <div class="cardRow">
       
      <div class="inRow">
        <div class="card" id="addModal">
          <p id="titleCard">Total Tagihan</p>
          <p id="amountCard">{{ formatUang(totalBalance) }}</p>
        </div>
        <div class="card" id="startBalance">
          <p id="titleCard">Penagihan Belum Dibayarkan</p>
          <p id="amountCard">{{ formatUang(noPaid) }}</p>
        </div>
        <div class="card" id="outCard">
          <p id="titleCard">Lewat Batas Waktu</p>
          <p id="amountCard">{{ formatUang(overTime) }}</p>
        </div>
      </div>
    </div>
    <div class="filterRow">
       
      <div class="inRow">
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Cabang</p>
          <b-select v-model="findBranchId" @change="this.getFindVendor">
            <option value="0" disabled>Pilih Cabang </option>
            <option v-for="e in this.branches" :key="e.id" :value="e.id"> {{e.branchName}} </option>
          </b-select>
        </div>
        <div class="inCol"  id="cardFilter">
          <p id="inputTitle">Proyek</p>
          <b-select v-model="findProjectId" @change="this.getDataTable">
            <option  v-for="e in this.findProcejts" :key="e.id" :value="e.id"> {{e.projectName}} </option>
          </b-select>
        </div>
        <div class="inCol"  id="cardFilter">
          <p id="inputTitle">Vendor</p>
          <b-select v-model="findVendorId"  @change="this.getDataTable">
            <option v-for="e in this.findVendors" :key="e.id" :value="e.id"> {{e.vendorName}} </option>
          </b-select>
        </div>
      </div>
      <div class="inRow">
        <div class="inCol" id="cardFilter">  
          <p id="inputTitle" >Tanggal Mulai</p>
          <b-input type="date"  v-model="startDate"  @change="this.getDataTable"></b-input>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Tanggal Selesai</p>
          <b-input type="date" v-model="endDate"  @change="this.getDataTable"></b-input>
        </div>
      </div>
      <div class="inRow">
        <div class="inCol" id="cardFilter">  
          <p id="inputTitle" >Tanggal Mulai Bayar</p>
          <b-input type="date"  v-model="startPay"  @change="this.getDataTable"></b-input>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Tanggal Selesai Bayar</p>
          <b-input type="date" v-model="endPay"  @change="this.getDataTable"></b-input>
        </div>
      </div>
      <div class="inRow">
        <div class="inCol" id="cardFilter">  
          <p id="inputTitle" >Tanggal Mulai Batas Waktu</p>
          <b-input type="date"  v-model="startDue"  @change="this.getDataTable"></b-input>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Tanggal Selesai Batas Waktu</p>
          <b-input type="date" v-model="endDue"  @change="this.getDataTable"></b-input>
        </div>
      </div>
    </div>
    <div id="titleRow">
      <b-button id="addModal" @click.prevent="showAdd()">Tambah</b-button>
      <b-button id="resetFilter" @click.prevent="resetFilter()">Reset Filter</b-button>
      <button @click.prevent="printCsv()" id="printer">
          <img src="../assets/printer.png" id="printer" alt="">
      </button>
    </div>
    <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p> 
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>Tanggal Surat</th>
            <th>Tanggal Kirim</th>
            <th>No.Reff</th>
            <th>Cabang</th>
            <th>Proyek</th>
            <th>Vendor</th>
            <th>Keterangan</th>
            <th>Jumlah</th>
            <th>Status Bayar</th>

            <th>Batas Waktu</th>
            <th>Tanggal Bayar</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in this.dataTable" :key="e.id">
            <td>{{formatDate(e.date)}} </td>
            <td>{{formatDate(e.sendDate)}} </td>
            <td> {{e.refNo}} </td>
            <td> {{e.Branch.branchName}} </td>
            <td> {{e.Project.projectName}} </td>
            <td> {{e.ReceivableVendor.vendorName}} </td>
            <td> {{e.notes}} </td>
            <td class="uang"> {{ formatUang(e.amount)}} </td>
            <td v-if="e.isPaid" class="text-center" @click.prevent="showChangeStatus(e)"> <img id="imgStatus" src="../assets/check.png" alt="" > </td>
            <td v-if="!e.isPaid" class="text-center" @click.prevent="showChangeStatus(e)">  <img id="imgStatus" src="../assets/wrong.png" alt="">  </td>

            <td>{{formatDate(e.dueDate)}} </td>
            <td>{{formatDate(e.payDate)}} </td>
            <!-- <td>{{ e.payDate }}</td> -->
            <!-- <td>{{ e.payDate == "NULL" || "" ? "":  formatDate(e.payDate)}} </td> -->
            <td id="action" class="text-center" >
                <button @click.prevent="showEdit(e)" title="Edit" >
                  <lord-icon
                    src="https://cdn.lordicon.com/nxaaasqe.json"
                    trigger="hover">
                  </lord-icon>
                </button>
                <button @click.prevent="confirmDelete(e.id)" title="Hapus">
                    <lord-icon
                        src="https://cdn.lordicon.com/gsqxdxog.json"
                        trigger="hover">
                    </lord-icon>
                </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    </div>

    <!-- modal -->
  <div class="modal" id="mainModal" tabindex="-1">
  <!-- <div class="modal-dialog"> -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Piutang</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" >&times; </span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="isEdit ? editData() : addData()">

          <div class="inRow">
            <div class="inCol">
              <p id="titleModal">Cabang</p>
              <b-select v-model="BranchId" required @change="this.getProject">
                <option value="0" disabled>Pilih Cabang </option>
                <option v-for="e in this.branches" :key="e.id" :value="e.id"> {{e.branchName}} </option>
              </b-select>
            </div>
            <div class="inCol">
              <p id="titleModal">Proyek</p>
              <b-select v-model="ProjectId" required>
                <option value="0" disabled>Pilih Proyek </option>
                <option v-for="e in this.projects" :key="e.id" :value="e.id"> {{e.projectName}}</option>
              </b-select>
            </div>
            <div class="inCol">
              <p id="titleModal">Vendor</p>
              <b-select v-model="ReceivableVendorId" required>
                <option value="0" disabled>Pilih Vendor </option>
                <option v-for="e in this.vendors" :key="e.id" :value="e.id"> {{e.vendorName}} </option>
              </b-select>
            </div>
        
        </div>
        <div class="inRow">
          <div class="inCol">
            <p id="inputTitle">Tanggal Surat</p>
            <b-input v-model="date" type="date" required></b-input>
          </div>
          <div class="inCol">
            <p id="inputTitle">Tanggal Kirim</p>
            <b-input v-model="sendDate" type="date" required></b-input>
          </div>
          <div class="inCol">
            <p id="inputTitle">Batas Waktu</p>
            <b-input v-model="dueDate" type="date" required></b-input>
          </div>
        </div>
        <div class="inRow">
          <div class="inCol">
            <p id="inputTitle">Jumlah</p>
            <b-input class="text-right" type="text" v-model="amount"  @input="currencyNumber($event)"></b-input>
          </div>
          <div class="inCol">
            <p id="inputTitle">Keterangan</p>
            <b-input type="text" v-model="notes" required></b-input>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary">Tambah</button>
        </div>
      </form>
      </div>
    </div>
  <!-- </div> -->
</div>
    <!-- changeStatus -->
    <div class="modal" id="changeStatus" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Ubah Status</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" >&times; </span>
                    </button>
                </div>
            <div class="modal-body">
                <form @submit.prevent="changeStatus()">
                    <div class="inRow">
                        <p id="titleModal"> Tanggal Bayar</p>
                        <b-input type="date" v-model="payDate"></b-input>
                      </div>
                   

                    <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary">Ok</button>
                    </div>
                </form>
            </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import moment from 'moment'
import csvDownload from 'json-to-csv-export'
export default {
  name: "Voucer",
  data() {
    return  {

      id: 0,
      projects: [],
      vendors: [],
      dataTable: [],
      types: [],
      currentPage: 1,
      perPage: 20,
			totalPage : 2,
      isLoading: false,
      isEdit: false,
      noPaid: 0,
      overTime: 0,
      totalBalance: 0,
      // filter
      findBranchId: 0,
      findProjectId: 0,
      findVendorId: 0,
      startDate: "",
      endDate: "",
      startPay: "",
      endPay: "",
      startDue: "",
      endDue: "",
      keyword: "",

      findVendors: [],
      findProcejts: [],
    
      // modal
      BranchId: 0,
      ProjectId: 0,
      ReceivableVendorId: 0,
      date:"",
      notes: "",
      amount: 0,
      sendDate: "",
      dueDate: "",
      payDate: ""
    }
  },
  created() {
    this.$store.dispatch("getBranch")
    this.getDataTable()

  },
  computed: {
    branches() {
      return this.$store.state.branches
    }
  },  
  methods: {

    showAdd() {
      this.isEdit = false
      $('#mainModal').modal('show')
    },
    showEdit(e) {
      this.getProject()
      this.id = e.id
      this.isEdit = true
      $('#mainModal').modal('show')
     this.BranchId=  e.BranchId
     this.date=  e.date
     this.notes=  e.notes
     this.amount=  e.amount
     this.ProjectId= e.ProjectId
     this.ReceivableVendorId= e.ReceivableVendorId
     this.transactionType= e.transactionType
     this.DebtTypeId= e.DebtTypeId
    },
    resetFilter() {
      this.findBranchId= 0
      this.findProjectId= 0
      this.findVendors= 0
      this.startDate= ""
      this.endDate= ""
      this.startPay= ""
      this.endPay= ""
      this.startDue= ""
      this.endDue= ""
      this.keyword= ""
      this.getDataTable()
    },
    showChangeStatus(e) {
      this.id = e.id
      if(e.isPaid) {
        this.changeStatus(e.id)
      } else {
        $('#changeStatus').modal('show')
      }
      this.payDate= ''

    },
    async changeStatus(id) {
      
      try {
        let setId = this.id
        if(id) {
          setId = id
        }
        let data2 = await axios({
          method: "patch",
          url: `/receivable/status/${setId}`,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          data: {
            payDate: this.payDate
          }
        })
        Swal.fire('Berhasil', 'Data berhasil diproses', 'success');
        $('#changeStatus').modal('hide')
        this.getDataTable()
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    },
    async getDataTable() {
      // alert("masuuk")
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/receivable",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            startDate: this.startDate,
            endDate: this.endDate,
            startPay: this.startPay,
            endPay: this.endPay,
            startDue: this.startDue,
            endDue: this.endDue,
            page: this.currentPage,
            limit: this.perPage,
            BranchId: this.findBranchId,
            ProjectId: this.findProjectId, 
            ReceivableVendorId: this.findVendorId, 

          }
        })
        console.log(data);
        this.dataTable = data.data2.result
        this.noPaid = data.data2.balance.noPaid
        this.overTime = data.data2.balance.overTime
        this.totalBalance = data.data2.balance.total
        let totalPage = Math.ceil(data.data2.dataLength / this.perPage)
        this.totalPage = totalPage
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
    async getProject() {
      try {
        let project = await axios({
          url: "/user/project",
          params: {
           BranchId:  this.BranchId
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.projects = project.data
        this.getVendor()
      } catch(err) {
        console.log(err);
      }
    },  

    async getVendor() {
      try {
        let vendor = await axios({
          url: "/receivable/vendor",
          params: {
           BranchId:  this.BranchId
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.vendors = vendor.data
      } catch(err) {
        console.log(err);
      }
    },  
    async getFindVendor() {
      try {
        this.getFIndProject()
        this.getDataTable()
        let vendor = await axios({
          url: "/receivable/vendor",
          params: {
           BranchId:  this.findBranchId
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.findVendors = vendor.data
      } catch(err) {
        console.log(err);
      }
    },  
    async getFIndProject() {
      try {
        let project = await axios({
          url: "/user/project",
          params: {
           BranchId:  this.findBranchId
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.findProcejts = project.data
      } catch(err) {
        console.log(err);
      }
    },  


    async printCsv() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/receivable/print",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            startDate: this.startDate,
            endDate: this.endDate,
            BranchId: this.findBranchId,
            ReceivableVendorId: this.findReceivableVendorId, 
            DebtTypeId: this.findDebtTypeId, 
            ProjectId: this.findProjectId, 
          }
        })
        const dataToConvert = {
          data: data,
          filename: 'Penagihan',
          delimiter: ',',
        }
        csvDownload(dataToConvert)
        this.isLoading = false
        Swal.fire('Ok', 'Download Anda Berhasil', 'success');
      } catch(err) {
        this.isLoading = false
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }

    },

    formatDate(date) {

      let result = ""
      if(date) {
        result =  moment(date).format("DD-MM-YYYY")
      }

      return result
    },

    cekStatus(data) {
      let result = ""
      if(data == 2) {
        result = 'By Planing'
      } else if(data == 1) {
        result= 'Urgent'
      }
      return result
    },
    currencyNumber(event, target) {
        console.log(event, "===================");
        let currentValue = event.trim().split('.').filter(e => e != '.' ).join('')

        console.log(currentValue, typeof currentValue," -----------");
        if( Number.isNaN(Number(currentValue))) {
          // this.amount = 10
          this.amount = this.formatUang(currentValue.split('').filter(e => !Number.isNaN( Number(e))).join(''))
          return
        }
        // currentValue = this.amount
        this.amount = this.formatUang(currentValue)
        // currentValue = this.amount
        // this.amount = this.amount.toLocaleString('id-ID')
        console.log(this.amount, "++++++++++++++");
    },

    formatUang(data) {
      // console.log(data);
			let uang = "";
      if(data) {
        data = data.toString();
        for (let i = 0; i < data.length; i++) {
          if ((data.length - i) % 3 == 0 && i !== 0) {
          uang += `.${data[i]}`;
          } else {
          uang += data[i];
          }
        }
      }
      return uang
    },



    async addData() {

      try {
        let amount = this.amount.trim().split('.').filter(e => e != '.' ).join('')

        let data ={
          BranchId:  this.BranchId,
          date:  this.date,
          notes:  this.notes,
          amount:  this.amount.trim().split('.').filter(e => e != '.' ).join(''),
          ProjectId: this.ProjectId,
          ReceivableVendorId: this.ReceivableVendorId,
          dueDate: this.dueDate,
          sendDate: this.sendDate,
        }

        let data2 = await axios({
          method: "POST",
          url: "/receivable",
          data: data,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        $('#mainModal').modal('hide')
        this.BranchId= 0
        this.ProjectId= 0
        this.ReceivableVendorId= 0
        this.transactionType= ""
        this.date=""
        this.DebtTypeId= ""
        this.notes= ""
        this.amount= 0
        Swal.fire('Berhasil', 'Data Berhasil Ditambahkan', 'success');
        this.getDataTable()
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    },

    async editData(e) {

      try {
        let payload = await {
          BranchId:  this.BranchId,
          date:  this.date,
          notes:  this.notes,
          amount:  this.amount,
          ProjectId: this.ProjectId,
          ReceivableVendorId: this.ReceivableVendorId,
          transactionType: this.transactionType,
          DebtTypeId: this.DebtTypeId,
        }

        let data2 = await axios({
          method: "put",
          url: `/debt/${this.id}`,
          data: payload,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        $('#mainModal').modal('hide')
        this.getDataTable()
        this.BranchId=  0
        this.date=  ""
        this.ProjectId=  ""
        this.ReceivableVendorId=  ""
        this.notes=  ""
        this.transactionType=  ""
        this.DebtTypeId=  ""
        this.amount=  0
        this.id=  0
        Swal.fire('Berhasil', 'Data Berhasil Ditambahkan', 'success');
        
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    }, 

    async deleteData(id) {
      
      try {
        
        let data = await axios.delete(`/receivable/${id}`, {
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Berhasil', 'Data Berhasil Dihapuskan', 'success');

      } catch(err) {
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');

      } 
    },
    async confirmDelete(id) {
      
      try {
        let data = await Swal.fire({
            title: 'Yakin?',
            text: 'Apakah Yakin ',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Hapus!!',
        })
        if (data.isConfirmed) {
            this.deleteData(id);
            this.getDataTable()
        } else {
                Swal.fire('Ok', 'Proses anda berhasil dibatalkan', 'error');
              }
              
              
            } catch(err) {
              
              Swal.fire('Maaf', 'Proses anda berhasil dibatalkan', 'error');
      }
    },
    addPage() {
      this.currentPage = this.currentPage + 1
      this.getDataTable()
    },
    minPage() {
      this.currentPage = this.currentPage - 1
      this.getDataTable()
    },
  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }

  #imgStatus {
    max-width: 24px;
    text-align: center;
  }




</style>