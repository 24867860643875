<template>
  <div class="mainPage">
    <p id="headPage">Tarif Rental</p>

    <div id="titleRow">
      <b-button id="addModal" @click.prevent="showAdd()" v-if="role == 'owner'">Tambah Data</b-button>
    </div>
    <div class="filterRow">
      <div class="inRow">
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Kata Kunci</p>
          <b-input v-model="keyword" @change="this.getDataTable"></b-input>
        </div>
      </div>
      <div class="inRow">
      </div>
    </div>
    <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p> 
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>Jenis</th>
            <th>Satuan</th>
            <th>Harga</th>
            <!-- <th colspan="2">Aksi</th> -->
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in this.dataTable" :key="e.id">
            <td> {{e.type}} </td>
            <td> {{e.rate}} </td>
            <td class="text-right"> {{formatUang(e.price)}} </td>
            <td id="action" class="text-center" >
                <button @click.prevent="showEdit(e)" title="Edit" >
                  <lord-icon
                    src="https://cdn.lordicon.com/nxaaasqe.json"
                    trigger="hover">
                  </lord-icon>
                </button>
                <button @click.prevent="confirmDelete(e.id)" title="Hapus">
                    <lord-icon
                        src="https://cdn.lordicon.com/gsqxdxog.json"
                        trigger="hover">
                    </lord-icon>
                </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    </div>

    <!-- modal -->
  <div class="modal" id="mainModal" tabindex="-1">
  <!-- <div class="modal-dialog"> -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Tarif Rental</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" >&times; </span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="isEdit ? editData() : addData()">

          <div class="inRow">
            <div class="inCol">
              <p id="titleModal">Tipe Kendaraan</p>
              <b-select v-model="add.type" required>
                <option v-for="(e, index) in rentType" :key="index" :value="e">{{ e }}</option>
              </b-select> 
            </div>
            <div class="inCol">
              <p id="titleModal">Rate</p>
              <b-select v-model="add.rate" required>
                <option v-for="(e, index) in rates" :key="index">{{ e }}</option>
              </b-select> 
            </div>
            <div class="inCol">
              <p id="titleModal">Harga Satuan</p>
              <b-input class="text-right" v-model="add.price" type="text"   @input="currencyNumber($event)" required></b-input>
            </div>
            <div class="inCol">
              <p id="titleModal">Catatan</p>
              <b-input class="text-right" v-model="add.notes" type="text"  ></b-input>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary"> {{ isEdit ? 'Edit' : 'Tambah' }} </button>
        </div>
      </form>
      </div>
    </div>
  <!-- </div> -->
  </div>

<!-- Vehicle -->

  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment'

export default {
  name: "Voucer",
  data() {
    return  {
      dataTable: [],
      rates: ['Harian', 'Mingguan', 'Bulanan'],
      id: 0,
      currentPage: 1,
      perPage: 20,
			totalPage : 1,
      isLoading: false,
      isEdit: false,
      keyword: '',
      add: {
        type: '',
        price: 0,
        rate: '',
        notes: ''
      },
      // filter
      produckName: "",
      type: "",
    }
  },
  created() {
    this.getDataTable()
    this.$store.dispatch("getBranch")
  },
  computed: {
    branches() {
      return this.$store.state.branches
    },
    rentType() {
      return this.$store.state.rentType
    },
    role() {
      return localStorage.getItem("role");
    },
  },  
  methods: {

    showAdd() {
      this.isEdit = false
      $('#mainModal').modal('show')
    },


    currencyNumber(event) {
        let currentValue = event.trim().split('.').filter(e => e != '.' ).join('')

        console.log(currentValue, typeof currentValue," -----------");
        if( Number.isNaN(Number(currentValue))) {
          // this.add.price = 10
          this.add.price = this.formatUang(currentValue.split('').filter(e => !Number.isNaN( Number(e))).join(''))
          return
        }
        // currentValue = this.add.price
        this.add.price = this.formatUang(currentValue)
        console.log(this.add.price);
    },
    formatUang(data) {
      // console.log(data);
			let uang = 0;
      if(data) {
        uang = ""
        data = data.toString();
        for (let i = 0; i < data.length; i++) {
          if ((data.length - i) % 3 == 0 && i !== 0) {
          uang += `.${data[i]}`;
          } else {
          uang += data[i];
          }
        }
      }
      return uang
    },
    showEdit(e) {
      console.log(e);
      this.add.name = e.name
      this.add.type = e.type
      this.add.size = e.size
      this.add.color = e.color
      this.add.detail = e.detail
      this.id = e.id
      this.isEdit = true
      $('#mainModal').modal('show')
    },

    addRow() {
      this.subItems.push({
        id: uuidv4(),
        MaterialId: 0,
        volume: 0
      })
    },

    async getDataTable() {
      this.isLoading = true
      try {
        let {data} = await axios({
          method: "GET",
          url: "/rent/type",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.dataTable = data
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },


    async addData() {
      this.add.price = this.add.price.trim().split('.').filter(e => e != '.' ).join('')
      try {
        console.log(this.add,"----------");
        let proses = await axios({
          method: "POST",
          url: "/rent/type",
          data: this.add,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.getDataTable()
        $('#mainModal').modal('hide')
        Swal.fire('Berhasil', 'Telah diproses', 'success');
      } catch(err) {
        console.log(err);
        this.isLoading = false
        Swal.fire('Maaf', 'Data adan tidak dapat diproses', 'error');
      }
    },
    async editData(e) {

      try {
        this.add.price = this.add.price.trim().split('.').filter(e => e != '.' ).join('')
        let data2 = await axios({
          method: "put",
          url: `/rent/type/${this.id}`,
          data: this.add,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        $('#mainModal').modal('hide')
        this.isEdit = false
        this.getDataTable()
        Swal.fire('Berhasil', 'Data Berhasil Diubah', 'success');
        
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    }, 

    async deleteData(id) {
      
      try {
        
        let data = await axios.delete(`/rent/type/${id}`, {
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Berhasil', 'Data Berhasil Dihapuskan', 'success');
        this.getDataTable()
      } catch(err) {
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
        console.log(err);
      } 
    },
    async confirmDelete(id) {
      
      try {
        let data = await Swal.fire({
            title: 'Yakin?',
            text: 'Apakah Yakin ',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Hapus!!',
        })
        if (data.isConfirmed) { 
            await this.deleteData(id);
            this.$store.dispatch("getBranch")
            this.getDataTable()
        } else {
                Swal.fire('Ok', 'Proses anda berhasil dibatalkan', 'error');
              }
              
              
            } catch(err) {
              
              Swal.fire('Maaf', 'Proses anda berhasil dibatalkan', 'error');
      }
    },

    addPage() {
      this.currentPage = this.currentPage + 1
          this.getDataTable()
    },
    minPage() {
      this.currentPage = this.currentPage - 1
          this.getDataTable()
    },
  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }



 




</style>