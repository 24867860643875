import { render, staticRenderFns } from "./SubBillMutation.vue?vue&type=template&id=2024062c&scoped=true&"
import script from "./SubBillMutation.vue?vue&type=script&lang=js&"
export * from "./SubBillMutation.vue?vue&type=script&lang=js&"
import style0 from "./SubBillMutation.vue?vue&type=style&index=0&id=2024062c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2024062c",
  null
  
)

export default component.exports