<template>
  <div class="mainPage">
    <p id="headPage">Saldo Material</p>
    <div class="cardRow">
       
      <div class="inRow">
        <div class="card" id="startBalance">
          <p id="titleCard">Total Material</p>
          <p id="amountCard">{{formatUang(totalBalance)}}</p>
        </div>
      </div>
    </div>
    <div class="filterRow">
       
      <div class="inRow">
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Cabang</p>
          <b-select v-model="BranchId" @change="this.getDatatable">
            <option value="0" disabled>Pilih Cabang </option>
            <option v-for="e in this.branches" :key="e.id" :value="e.id"> {{e.branchName}} </option>
          </b-select>
        </div>
        <div class="inCol"  id="cardFilter">
          <p id="inputTitle">Jenis Material</p>
          <b-select v-model="MaterialId" @change="this.getDatatable">
            <option v-for="e in this.types" :key="e.id" :value="e.id"> {{ e.materialName }} </option>
          </b-select>
        </div>
      </div>
    </div>
    <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p> 
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>Nama Cabang</th>
            <th>Proyek</th>
            <th>Jenis Material</th>
            <th>Satuan</th>
            <th>Volume</th>
            <th>Jumlah</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in this.dataTable" :key="e.id">
            <td>{{ e.Branch.branchName }}</td>
            <td>{{ e.Project.projectName }}</td>
            <td>{{ e.Material.materialName }}</td>
            <td>{{ e.Material.detail }}</td>
            <td class="text-right">{{ formatUang(e.volume)}}</td>
            <td class="text-right"> {{ formatUang(e.amount)}}</td>
          </tr>
        </tbody>
      </table>
      <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    </div>


  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import moment from 'moment'
import csvDownload from 'json-to-csv-export'
export default {
  name: "Voucer",
  data() {
    return  {
      id: 0,
      dataTable: [],
      types: [],
      materialsType: [],
      currentPage: 1,
      perPage: 20,
			totalPage : 1,
      isLoading: false,
      isEdit: false,
      BranchId: 0,
      MaterialId: 0,
      totalBalance: 0,
      
      

    }
  },
  created() {
    this.$store.dispatch("getBranch")
    this.getDatatable()
    this.getType()
  },
  computed: {
    branches() {
      return this.$store.state.branches
    }
  },  
  methods: {
    async getDatatable() {

      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/material/balance",
          data: {
            branchName : this.branchName,
          },
          params: {
            page: this.currentPage,
            limit: this.perPage,
            BranchId: this.BranchId,
            MaterialId: this.MaterialId
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.dataTable = data.data2.result
        this.totalBalance = data.data2.total
        let totalPage = Math.ceil(data.data2.dataLength / this.perPage)
				this.totalPage = totalPage
        this.isLoading = false
      } catch(err) {
        console.log(err);
        this.isLoading = false
      }
    },
    async getType() {
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/material/type",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.types = data
      } catch(err) {
        console.log(err);
      }
    },
    formatUang(data) {
      // console.log(data);
			let uang = 0;
      if(data) {
        uang = ""
        data = data.toString();
        for (let i = 0; i < data.length; i++) {
          if ((data.length - i) % 3 == 0 && i !== 0) {
          uang += `.${data[i]}`;
          } else {
          uang += data[i];
          }
        }
      }
      return uang
    },
    addPage() {
      this.currentPage = this.currentPage + 1
          this.$store.dispatch("getBranch")
    },
    minPage() {
      this.currentPage = this.currentPage - 1
          this.$store.dispatch("getBranch")
    },
  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }




</style>