<template>
  <div class="mainPage">
    <p id="headPage">Saldo APD</p>
    <div class="cardRow">

      <div class="inRow">
        <div class="card" id="startBalance">
          <p id="titleCard">Total Barang</p>
          <p id="amountCard">{{formatUang(totalBalance)}}</p>
        </div>
      </div>
    </div>
    <div class="filterRow">
       
      <div class="inRow">
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Cabang</p>
          <b-select v-model="BranchId" @change="this.getProject">
            <option value="0" >Semua Cabang</option>
            <option v-for="e in this.branches" :key="e.id" :value="e.id"> {{e.branchName}} </option>
          </b-select>
        </div>
        <div class="inCol"  id="cardFilter">
          <p id="inputTitle">Proyek</p>
          <b-select v-model="ProjectId" @change="this.getDataTable">
            <option  v-for="e in this.projects" :key="e.id" :value="e.id"> {{e.projectName}} </option>
          </b-select>
        </div>
        <div class="inCol"  id="cardFilter">
          <p id="inputTitle">Jenis Sparepart</p>
          <b-input v-model="keyword"  @change="this.getDataTable"></b-input>
        </div>
      </div>
    </div>
    <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p> 
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>Nama - Merek - Ukuran - Keterangan</th>
            <th>Volume</th>
            <th>Jumlah</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in this.dataTable" :key="e.id">
            <td>{{ e.Apd.name}} -{{ e.Apd.type }} - {{ e.Apd.size }} - {{ e.Apd.color }} -{{ e.Apd.detail }}</td>
            <td class="text-right">{{ formatUang(e.volume) }}</td>
            <td class="text-right">{{ formatUang(e.amount) }}</td>
          </tr>
        </tbody>
      </table>
      <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    </div>


  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import moment from 'moment'
import csvDownload from 'json-to-csv-export'
export default {
  name: "Voucer",
  data() {
    return  {
      id: 0,
      dataTable: [],
      projects: [],
      currentPage: 1,
      perPage: 20,
			totalPage : 1,
      isLoading: false,
      isEdit: false,
      BranchId: 0,
      BranchId: 0,
      ProjectId: 0,
      keyword: '',
      totalBalance: 0
      

    }
  },
  created() {
    this.$store.dispatch("getBranch")
    this.getDataTable()

  },
  computed: {
    branches() {
      return this.$store.state.branches
    }
  },  
  methods: {
    async getDataTable() {

      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/apd/balance",
          params: {
            page: this.currentPage,
            limit: this.perPage,
            BranchId: this.BranchId,
            ProjectId: this.ProjectId,
            keyword: this.keyword
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.dataTable = data.data2.result
        this.totalBalance = data.totalBalance
        let totalPage = Math.ceil(data.data2.dataLength / this.perPage)
				this.totalPage = totalPage
        this.isLoading = false
        // this.getProject()
        console.log(data);
      } catch(err) {
        console.log(err);
        this.isLoading = false
      }
    },
    async getProject() {
      try {
        let {data} = await axios({
          url: "/user/project",
          params: {
           BranchId:  this.BranchId
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.projects = data
        this.getDataTable()
      } catch(err) {
        console.log(err);
      }
    },  
    formatUang(data) {
      // console.log(data);
			let uang = 0;
      if(data) {
        uang = ""
        data = data.toString();
        for (let i = 0; i < data.length; i++) {
          if ((data.length - i) % 3 == 0 && i !== 0) {
          uang += `.${data[i]}`;
          } else {
          uang += data[i];
          }
        }
      }
      return uang
    },
    async getSparepart() {
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/apd",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        console.log(data);
        this.sparepart = data

      } catch(err) {

        console.log(err);
      }
    },
    addPage() {
      this.currentPage = this.currentPage + 1
          this.getDataTable()
    },
    minPage() {
      this.currentPage = this.currentPage - 1
          this.getDataTable()
    },
  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }




</style>