<template>
  <div class="mainPage">
    <p id="headPage">Jenis Material</p>

    <div id="titleRow">
      <b-button id="addModal" @click.prevent="showAdd()">Tambah Data</b-button>
    </div>
    <!-- <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p> 
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div> -->
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>Nama Material</th>
            <th>Satuan</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in this.dataTable" :key="e.id">
            <td> {{e.materialName}} </td>
            <td> {{e.detail}} </td>
            <td id="action" class="text-center" >
                <button @click.prevent="showEdit(e)" title="Edit" >
                  <lord-icon
                    src="https://cdn.lordicon.com/nxaaasqe.json"
                    trigger="hover">
                  </lord-icon>
                </button>
                <button @click.prevent="confirmDelete(e.id)" title="Hapus">
                    <lord-icon
                        src="https://cdn.lordicon.com/gsqxdxog.json"
                        trigger="hover">
                    </lord-icon>
                </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- modal -->
  <div class="modal" id="mainModal" tabindex="-1">
  <!-- <div class="modal-dialog"> -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Jenis Material</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" >&times; </span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="isEdit ? editData() : addData()">

          <div class="inRow">
            <div class="inCol">
              <p id="titleModal">Nama Material</p>
              <b-input v-model="materialName" required ></b-input>
            </div>
            <div class="inCol">
              <p id="titleModal">Satuan </p>
              <b-input v-model="detail" required placeholder="Meter / Kg / dll."></b-input>
            </div>
        
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary"> {{ isEdit ? 'Edit' : 'Tambah' }} </button>
        </div>
      </form>
      </div>
    </div>
  <!-- </div> -->
</div>

<!-- Vehicle -->

  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import moment from 'moment'
import csvDownload from 'json-to-csv-export'
export default {
  name: "Voucer",
  data() {
    return  {
      dataTable: [],
      id: 0,
      currentPage: 1,
      perPage: 20,
			totalPage : 2,
      isLoading: false,
      isEdit: false,
      
      // filter
      materialName: "",
      detail: "",
    }
  },
  created() {
    this.getDataTable()
    this.$store.dispatch("getBranch")
  },
  computed: {
    branches() {
      return this.$store.state.branches
    }
  },  
  methods: {

    showAdd() {
      this.isEdit = false
      this.materialName = ""
      $('#mainModal').modal('show')
    },
    showAddVehicle() {

      $('#vehicleModal').modal('show')

    },
    showEdit(e) {
      this.id = e.id
      this.isEdit = true
      $('#mainModal').modal('show')
      this.materialName = e.materialName
    },
    async getDataTable() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/material/type",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            page: this.currentPage,
            limit: this.perPage,
          }
        })
        this.dataTable = data
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },


    async addData() {

      try {
        
        let proses = await axios({
          method: "POST",
          url: "/material/type",
          data: {
            materialName : this.materialName,
            detail : this.detail,
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.materialName = "",
        this.getDataTable()
        $('#mainModal').modal('hide')
        Swal.fire('Berhasil', 'Telah diproses', 'success');
      } catch(err) {
        console.log(err);
        this.isLoading = false
      }
    },
    async editData(e) {

      try {
        let payload = {
          materialName:  this.materialName,
          detail:  this.detail,
        }

        let data2 = await axios({
          method: "put",
          url: `/material/type/${this.id}`,
          data: payload,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        $('#mainModal').modal('hide')
        this.materialName=  ""
        this.id=  0
        this.isEdit = false
        this.getDataTable()
        this.$store.dispatch("getBranch")

        Swal.fire('Berhasil', 'Data Berhasil Diubah', 'success');
        
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    }, 

    async deleteData(id) {
      
      try {
        
        let data = await axios.delete(`/material/type/${id}`, {
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Berhasil', 'Data Berhasil Dihapuskan', 'success');
        this.$store.dispatch("getBranch")
        this.getDataTable()
      } catch(err) {
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');

      } 
    },
    async confirmDelete(id) {
      
      try {
        let data = await Swal.fire({
            title: 'Yakin?',
            text: 'Apakah Yakin ',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Hapus!!',
        })
        if (data.isConfirmed) { 
            await this.deleteData(id);
            this.$store.dispatch("getBranch")
            this.getDataTable()
        } else {
                Swal.fire('Ok', 'Proses anda berhasil dibatalkan', 'error');
              }
              
              
            } catch(err) {
              
              Swal.fire('Maaf', 'Proses anda berhasil dibatalkan', 'error');
      }
    },
    addPage() {
      this.currentPage = this.currentPage + 1
          this.$store.dispatch("getBranch")
    },
    minPage() {
      this.currentPage = this.currentPage - 1
          this.$store.dispatch("getBranch")
    },
  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }




</style>