<template>
  <div class="mainPage">
    <p id="headPage">Daftar Mutasi Perlengkapan</p>

    <div id="titleRow">
      <!-- <b-button id="addModal" @click.prevent="showAdd()">Tambah Data</b-button> -->
    </div>
    <div class="filterRow">
      <div class="inRow">
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Dari Tanggal</p>
          <b-input
            v-model="filter.startDate"
            type="date"
            @change="this.getDataTable"
          ></b-input>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Sampai Tanggal</p>
          <b-input
            v-model="filter.endDate"
            type="date"
            @change="this.getDataTable"
          ></b-input>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Status</p>
          <b-select v-model="filter.status" @change="this.getDataTable">
            <option value="">Seluruh Barang</option>
            <option v-for="(e,index) in this.statuses" :key="index">{{ e }}</option>
          </b-select>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Keyword</p>
          <b-input
            v-model="filter.keyword"
            @change="this.getDataTable"
          ></b-input>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Status Barang</p>
          <b-select v-model="statusIn" @change="this.getDataTable">
            <option value="0">Seluruh</option>
            <option value="1">Sudah Kembali</option>
            <option value="2">Belum Kembali</option>
          </b-select>
        </div>
      </div>
      <div class="inRow"></div>
    </div>
    <div class="pagination mt-2">
      <button
        type="button"
        class="mr-1"
        id="pageButton"
        v-if="currentPage > 1"
        @click.prevent="minPage()"
      >
        &lt;
      </button>
      <button class="mr-1" id="pageButton" v-if="currentPage <= 1">&lt;</button>
      <p class="mr-1">{{ this.currentPage }}</p>
      <p class="mr-1">/</p>
      <p class="mr-1">{{ this.totalPage }}</p>
      <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()">
        >
      </button>
      <button id="pageButton" v-if="this.currentPage >= this.totalPage">
        >
      </button>
    </div>
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width: 300px; height: 300px"
        >
        </lord-icon>
      </div>
      <table
        v-if="!isLoading"
        class="table-bordered table-hover"
        id="tableData"
      >
        <thead>
          <tr></tr>
          <tr>
            <th rowspan="2">Kode</th>
            <th rowspan="2">Kode Barang</th>
            <th rowspan="2">Nama Barang</th>
            <th colspan="3">Ambil</th>
            <th colspan="3">kembali</th>
            <th rowspan="2">Status</th>
            <th rowspan="2">Tersedia</th>
            <th rowspan="2">Aksi</th>
          </tr>
          <tr>
            <th>Tanggal</th>
            <th>Jam</th>
            <th>Ket.</th>
            <th>Tanggal</th>
            <th>Jam</th>
            <th>Ket.</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in this.dataTable" :key="e.id">
            <!-- <td>{{ e }}</td> -->
            <!-- <td>{{ e }}</td> -->
            <td>{{ e.Inventory.code }}</td>
            <td>{{ e.Inventory.name }}</td>
            <td>{{ formatDate(e.date) }}</td>
            <td>{{ e.timeOut }}</td>
            <td>{{ e.notesOut }}</td>
            <td>{{ formatDate(e.dateIn) }}</td>
            <td>{{ e.timeIn }}</td>
            <td>{{ e.notesIn }}</td>
            <td>{{ e.status }}</td>
            <td style="background-color: red" v-if="e.timeIn == ''"></td>
            <td style="background-color: #76B947" v-if="e.timeIn !== ''"></td>
            <td id="action" class="text-center">
              <button @click.prevent="setPrintData(e)"><img src="../assets/print2.png" alt=""></button>
              <button
                @click.prevent="showIn('in', e.Inventory.id)"
                v-if="e.timeIn == ''"
              >
                <lord-icon
                  src="https://cdn.lordicon.com/ytgufzvb.json"
                  colors="primary:#121331,secondary:#e83a30"
                  trigger="hover"
                >
                </lord-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination mt-2">
        <button
          type="button"
          class="mr-1"
          id="pageButton"
          v-if="currentPage > 1"
          @click.prevent="minPage()"
        >
          &lt;
        </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1">
          &lt;
        </button>
        <p class="mr-1">{{ this.currentPage }}</p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{ this.totalPage }}</p>
        <button
          type="button"
          id="pageButton"
          v-if="this.currentPage < this.totalPage"
          @click.prevent="addPage()"
        >
          >
        </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage">
          >
        </button>
      </div>
    </div>

    <!-- modal -->
    <div class="modal" id="inOutModal" tabindex="-1">
      <!-- <div class="modal-dialog"> -->
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-if="this.inOut.type == 'in'">
            Inventaris Masuk
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times; </span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="isEdit ? editData() : addInOut()">
            <div class="inRow">
              <div class="inCol">
                <p id="titleModal">Tanggal</p>
                <b-input v-model="inOut.date" type="date" required></b-input>
              </div>
              <div class="inCol">
                <p id="titleModal">Jam</p>
                <b-input v-model="inOut.time" type="time" required></b-input>
              </div>
              <div class="inCol">
                <p id="titleModal">Status</p>
                <b-select v-model="inOut.status" >
                  <option v-for="(e,index) in this.statuses" :key="index">{{ e }}</option>
                </b-select>
              </div>
              <div class="inCol">
                <p id="titleModal">notes</p>
                <b-input v-model="inOut.notes" required></b-input>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" class="btn btn-primary">
                {{ isEdit ? "Edit" : "Tambah" }}
              </button>
            </div>
          </form>
        </div>
      </div>
      <!-- </div> -->
    </div>
    <!-- Print -->
    <div id="printArea">
      <div class="headPrint">
        <p id="titlePrint">Invetarisir</p>
        <p id="brancName">{{ print.branchName }}</p>
        <p id="datePrint">{{ print.date }}</p>
      </div>
      <hr />
      <div class="mainPrint">
        <table class="table table-borderless" id="printTable">
          <tr>
            <td id="keyTable">Nomor</td>
            <td id="titikDua">:</td>
            <td>{{ print.nomor }}</td>
          </tr>
          <tr>
            <td id="keyTable">Nomor - Nama Inv</td>
            <td id="titikDua">:</td>
            <td>{{ print.invCode }} - {{ print.invName }}</td>
          </tr>
          <tr>
            <td id="keyTable">Waktu Keluar / Ket.</td>
            <td id="titikDua">:</td>
            <td>
              {{ formatDate(print.dateOut) }} - {{ print.timeOut }} / {{ print.notesOut }} 
            </td>
          </tr>
          <tr>
            <td id="keyTable">Waktu Kembali / Ket.</td>
            <td id="titikDua">:</td>
            <td>{{ formatDate(print.dateIn) }} - {{ print.timeIn }} / {{ print.notesIn }} </td>
          </tr>
          <tr>
            <td id="keyTable">Status</td>
            <td id="titikDua">:</td>
            <td> {{ print.status }} </td>
          </tr>
        </table>
        <hr />
      </div>
      <div class="signArea">
        <div class="inRow">
          <div class="inCol">
            <p id="name">Gudang</p>
            <p>(............................)</p>
          </div>
          <div class="inCol">
            <p id="name">Penerima Barang</p>
            <p>(............................)</p>
          </div>
          <div class="inCol">
            <p id="name">Pengembali Barang</p>
            <p>(............................)</p>
          </div>
        </div>
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
import axios from "../API/axios";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import html2pdf from "html2pdf.js";

export default {
  name: "Voucer",
  data() {
    return {
      id: 0,
      dataTable: [],
      currentPage: 1,
      perPage: 20,
      totalPage: 1,
      isLoading: false,
      isEdit: false,
      statuses: ['baik','rusak','hilang'],
      status: '',

      add: {
        BranchId: 0,
        name: "",
        type: "",
        detail: "",
        amount: 0,
      },
      inOut: {
        BranchId: 0,
        date: "",
        time: "",
        notes: "",
        type: "",
        status: 'baik'
      },
      print: {
        branchName: "",
        nomor: "",
        date: "",
        invCode: "",
        invName: "",
        dateOut: "",
        timeOut: "",
        noteOut: "",
        dateIn: "",
        timeIn: "",
        notesIn: "",
        notesOut: "",
        status: ''
      },
      // filter
      filter: {
        keyword: "",
        startDate: "",
        endDate: "",
        status: ''
      },
      statusIn: "0",
      produckName: "",
      type: "",
    };
  },
  created() {
    this.getDataTable();
    this.$store.dispatch("getBranch");
  },
  computed: {
    branches() {
      return this.$store.state.branches;
    },
  },
  methods: {
    showAdd() {
      this.isEdit = false;
      this.produckName = "";
      this.type = "";
      $("#mainModal").modal("show");
    },

    showIn(type, id) {
      this.id = id;
      this.inOut.type = type;
      $("#inOutModal").modal("show");
    },

    async getDataTable() {
      this.isLoading = true;
      try {
        let { data } = await axios({
          method: "GET",
          url: "/inventory/mutation",
          headers: {
            access_token: localStorage.getItem("access_token"),
          },
          params: {
            page: this.currentPage,
            limit: this.perPage,
            keyword: this.filter.keyword,
            startDate: this.filter.startDate,
            endDate: this.filter.endDate,
            statusIn: this.statusIn,
            status: this.filter.status
          },
        });
        this.dataTable = data.data2.result;
        let totalPage = Math.ceil(data.data2.dataLength / this.perPage);
        this.totalPage = totalPage;
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
    },

    exportToPDF() {
      this.isLoading = true;
      document.getElementById("printArea").style.display = "block";
      html2pdf(document.getElementById("printArea"), {
        margin: 1,
        filename: "Inventory Mutation.pdf",
      }).then((e) => {
        document.getElementById("printArea").style.display = "none";
        this.isLoading = false;
      });
    },

    async addInOut() {
      try {
        let proses = await axios({
          method: "POST",
          url: `/inventory/out/${this.id}`,
          data: this.inOut,
          headers: {
            access_token: localStorage.getItem("access_token"),
          },
        });
        this.inOut = {
          BranchId: 0,
          date: "",
          time: "",
          notes: "",
          type: "",
        },
        this.getDataTable();
        $("#inOutModal").modal("hide");
        Swal.fire("Berhasil", "Telah diproses", "success");
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
    async editData(e) {
      try {
        let payload = {
          name: this.add.name,
          type: this.add.type,
          detail: this.add.detail,
        };

        let data2 = await axios({
          method: "put",
          url: `/sparepart/${this.id}`,
          data: payload,
          headers: {
            access_token: localStorage.getItem("access_token"),
          },
        });

        $("#mainModal").modal("hide");
        (this.add.name = ""),
          (this.add.type = ""),
          (this.add.detail = ""),
          (this.id = 0);
        this.isEdit = false;
        this.getDataTable();
        Swal.fire("Berhasil", "Data Berhasil Diubah", "success");
      } catch (err) {
        console.log(err);
        Swal.fire("Maaf", "Periksa Kembali data anda", "error");
      }
    },

    formatDate(date) {
      let result = "";
      if (date) result = moment(date).format("DD-MM-YYYY");
      if (result == "Invalid date") result = "";
      return result;
    },

    testButton() {
      alert("ok");
    },

    setPrintData(e) {
      (this.print.branchName = e.Branch.branchName),
        (this.print.date = moment(new Date()).format("DD-MM-YYYY"));
      this.print.nomor = e.refNo;
      this.print.status = e.status
      this.print.invCode = e.Inventory.code;
      this.print.invName = e.Inventory.name;
      this.print.dateOut = e.date;
      this.print.timeOut = e.timeOut;
      this.print.noteOut = e.noteOut;
      this.print.dateIn = e.dateIn ? e.dateIn : "-";
      this.print.timeIn0 = e.timeIn ? e.timeIn : "-";
      this.print.notesIn = e.notesIn ? e.notesIn : '-' ;
      this.print.notesOut = e.notesOut;
      this.exportToPDF();
    },

    addPage() {
      this.currentPage = this.currentPage + 1;
      this.getDataTable();
    },
    minPage() {
      this.currentPage = this.currentPage - 1;
      this.getDataTable();
    },
  },
};
</script>

<style scoped>
.cardRow {
  /* display: flex; */
  width: 100%;
  /* background-color: aqua; */
}


</style>
